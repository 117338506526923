import { useEffect, useRef, useState } from "react";
import useHttp from "../hooks/use-http";
import useInput from "../hooks/use-input";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "../assets/styles/pages/_collection-page.scss";
import closeGrey from "../assets/images/icons/close_grey.svg";
import { ReactComponent as IconShare } from "../assets/images/icons/iconShareCollection.svg";
import { ReactComponent as Spinner } from "../assets/images/icons/spinner.svg";
import { ReactComponent as IconUploadImage } from "../assets/images/icons/iconUploadImage.svg";
import { ReactComponent as IconCreate } from "../assets/images/icons/iconCreate.svg";
import { ReactComponent as IconCorrectGreen } from "../assets/images/icons/iconCorrectGreen.svg";
import ProfileContainer from "../containers/ProfileContainer";
import ButtonBlack from "../UI/ButtonBlack";

import GalleryImage from "../components/GalleryImage";
import ModalProfile from "../UI/ModalProfile";

const CollectionPage = () => {
  const history = useHistory();
  const timeoutRef = useRef(null);
  const timeoutSuccessRef = useRef(null);
  const [userCollectionsData, setUserCollectionsData] = useState([]);
  const [userImages, setUserImages] = useState(false);
  const userToken = useSelector((state) => state.authReducer.userToken);
  const userEmail = useSelector((state) => state.authReducer.currentUser.email);
  const [shareCollectionModal, setShareCollectionModal] = useState(false);
  const [collectionUrl, setCollectionUrl] = useState("");
  const [copied, setCopied] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset,
  } = useInput((value) =>
    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)
  );
  const {
    isLoading: isLoadingGet,
    error: errorGet,
    sendRequest: sendGetUserCollections,
  } = useHttp();
  const {
    isLoading,
    error,
    sendRequest: sendShareCollectionRequest,
  } = useHttp();
  const {
    isLoading: isLoadingGetCollectionUrl,
    // error: errorCollectionUrl,
    sendRequest: sendGetCollectionUrlRequest,
  } = useHttp();

  const onSuccessDownloadHandler = () => {
    setSuccessMessage("The Image is downloading!");
  };
  const onSuccessShareHandler = () => {
    setSuccessMessage("Shared email Image successfully!");
  };

  const onSuccessDeleteHandler = (id) => {
    let userCollectionsDataCopy = userCollectionsData.filter(
      (image) => image.id !== id
    );
    setUserCollectionsData(userCollectionsDataCopy);
    setSuccessMessage("Image deleted successfully!");
    setUserImages(userCollectionsDataCopy.length === 0);
  };
  const closeSuccessMessage = () => {
    setSuccessMessage(false);
  };

  const handleGetCollectionUrl = (response) => {
    setShareCollectionModal(true);
    setCollectionUrl(response.url);
  };
  const closeShareModalHandler = () => {
    setCopied(false);
    setShareCollectionModal(false);
  };
  const openShareCollectionModal = () => {
    sendGetCollectionUrlRequest(
      {
        url: "/api/collection-share",
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      },
      handleGetCollectionUrl
    );
  };
  const handleShareCollectionResponse = () => {
    setShareCollectionModal(false);
    reset();
    setSuccessMessage("Shared collection Images successfully!");
  };

  const shareCollectionHandler = () => {
    const formData = new FormData();
    formData.append("email_to", emailValue);
    formData.append("email_from", userEmail);
    sendShareCollectionRequest(
      {
        url: "/api/collection-share",
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      },
      handleShareCollectionResponse
    );
  };

  const handleGetUserCollections = (response) => {
    setUserCollectionsData(response.collection.images);
  };
  useEffect(() => {
    sendGetUserCollections(
      {
        url: "/api/user/collection",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      },
      handleGetUserCollections
    );
  }, [sendGetUserCollections, userToken]);

  const resetTimeout = (ref) => {
    if (ref.current) {
      clearTimeout(ref.current);
    }
  };

  useEffect(() => {
    resetTimeout(timeoutSuccessRef);
    setTimeout(() => {
      timeoutSuccessRef.current = setSuccessMessage("");
    }, [2500]);
    return () => {
      resetTimeout(timeoutSuccessRef);
    };
  }, [successMessage]);

  useEffect(() => {
    resetTimeout(timeoutRef);
    timeoutRef.current = setTimeout(() => {
      setCopied(false);
    }, 3500);
    return () => {
      resetTimeout(timeoutRef);
    };
  }, [copied]);

  return (
    <ProfileContainer>
      {shareCollectionModal && (
        <ModalProfile onClose={closeShareModalHandler}>
          <div className="my-collection-share-modal">
            <div className="my-collection-share-modal__heading">
              <h2>Share collection</h2>
              <button onClick={closeShareModalHandler}>
                <img src={closeGrey} alt="close-icon" />
              </button>
            </div>
            <div className="my-collection-share-modal__content">
              <div
                className={
                  copied
                    ? "my-collection-share-modal__clipboard-container my-collection-share-modal__no-hover"
                    : "my-collection-share-modal__clipboard-container"
                }
              >
                <p>Share this image via</p>
                <span className="tooltiptext">Copy to clipboard</span>

                <CopyToClipboard
                  text={collectionUrl}
                  onCopy={() => setCopied(true)}
                >
                  <button className="my-collection-share-modal__clipboard--btn">
                    Public share link
                  </button>
                </CopyToClipboard>
              </div>

              {copied && (
                <div className="my-collection-share-modal__clipboard">
                  <div>
                    <IconCorrectGreen /> <p>Copied to clipboard</p>
                  </div>
                  <span>{collectionUrl}</span>
                </div>
              )}

              <div className="my-collection-share-modal__line">
                <span>or</span>
              </div>
              <p>
                Share this collection to a friend by entering their email
                address below.
              </p>
              <input
                type="email"
                onChange={emailChangeHandler}
                value={emailValue}
                onBlur={emailBlurHandler}
                placeholder="Enter your friend email"
              />
              <span className="my-collection-share-modal__error">{error}</span>
              {emailHasError && (
                <span className="my-collection-share-modal__error">
                  Please enter valid email address.
                </span>
              )}
            </div>
            <div className="my-collection-share-modal__actions">
              <button
                className="my-collection-share-modal__actions--cancel"
                onClick={closeShareModalHandler}
              >
                Cancel
              </button>
              {isLoading && <Spinner />}
              {!isLoading && (
                <button
                  onClick={shareCollectionHandler}
                  className={
                    !emailHasError
                      ? "my-collection-share-modal__actions--send"
                      : "my-collection-share-modal__actions--send my-collection-share-modal__actions--send my-collection-share-modal__actions--send-error"
                  }
                  disabled={!emailIsValid}
                >
                  Send
                </button>
              )}
            </div>
          </div>
        </ModalProfile>
      )}
      <div className="my-collection">
        <div className="my-collection__header">
          <h1>My Collection</h1>
          {!isLoadingGetCollectionUrl && (
            <ButtonBlack
              onClick={openShareCollectionModal}
              disabled={errorGet || userImages}
            >
              <IconShare />
              Share Collection
            </ButtonBlack>
          )}
          {isLoadingGetCollectionUrl && <Spinner />}
        </div>
        {successMessage && (
          <ModalProfile padding onClose={closeSuccessMessage}>
            <div className="my-collection__success">
              <div>
                <h3>{successMessage}</h3>
                <IconCorrectGreen />
              </div>

              <button onClick={closeSuccessMessage}>Okey</button>
            </div>
          </ModalProfile>
        )}
        {(errorGet || userImages) && (
          <div className="my-collection__error">
            <IconUploadImage className="my-collection__icon" />
            <h3>You've not added any images yet</h3>
            <p>Would you like to add one ?</p>
            <ButtonBlack onClick={() => history.push("/")}>
              <IconCreate />
              Create Image
            </ButtonBlack>
          </div>
        )}
        <div className="my-collection__galery">
          {isLoadingGet && <Spinner />}
          {userCollectionsData
            .slice(0)
            .reverse()
            .map((item) => {
              return (
                <GalleryImage
                  data={item}
                  key={item.id}
                  onSuccessDownload={onSuccessDownloadHandler}
                  onSuccessShare={onSuccessShareHandler}
                  onSuccessDelete={onSuccessDeleteHandler}
                />
              );
            })}
        </div>
      </div>
    </ProfileContainer>
  );
};

export default CollectionPage;
