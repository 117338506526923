import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Range } from "react-range";
import Select from "react-select";
//styles
import "../assets/styles/components/_text-properties.scss";

//UI
import ColorPicker from "../UI/ColorPicker";

//redux actions
import {
  set_active_font,
  set_active_color,
  set_active_opacity,
} from "../store/actions/stylesActions";

//react .js imports
import Backdrop from "../UI/Backdrop";
import Auxilary from "../hoc/Auxilary";
var WebFont = require('webfontloader');

const options = [
  { value: "Arial", label: "Arial" },
  { value: "Times New Roman", label: "Times New Roman" },
];

const TextProperties = (props) => {
  const dispatch = useDispatch();
  const userPlan = useSelector((state) => state.authReducer.currentUser.is_pro);

  let activeComponent = props.activeComponent;
  const [colorPicker, setColorPicker] = useState(false);
  const handleChangeColor = (value) => {
    if (!activeComponent) return;
    dispatch(
      set_active_color(value, props.activeComponentID, activeComponent.bubbleId)
    );
  };

    useEffect(() => {
        if(options.length < 10) {
            fetchGoogleFonts();
        }
    }, []);
    const fetchGoogleFonts = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL+'/api/fonts/google-web-fonts');
            const result = await response.json();

            result.items.forEach((font) => {
                if (font.family && font.files.regular) {
                    let url = font.files.regular.replace(/^http:/, 'https:');
                    options.push({value: font.family, label: font.family, url: url});
                }
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

  const handleActiveOpacity = (values) => {
    if (!activeComponent) return;
    dispatch(
      set_active_opacity(
        values,
        props.activeComponentID,
        activeComponent.bubbleId
      )
    );
  };

  const handleFontChange = (selectedOption) => {
    if(selectedOption.value != 'Arial' && selectedOption.value != 'Times New Roman') {
        let style = document.createElement('style');
        style.textContent = '@font-face { font-family: \''+selectedOption.value+'\'; src: url(\''+selectedOption.url+'\'); }';
        document.head.appendChild(style);
        
        WebFont.load({
            custom: {
                families: [selectedOption.value]
              }
        });
    }
    dispatch(
      set_active_font(
        selectedOption,
        props.activeComponentID,
        activeComponent.bubbleId
      )
    );
  };

  return (
    <div className="text-properties" id="overlay__text-properties" onClick={props.onClick}>
      {userPlan && activeComponent ? null : (
        <div className="hide-styles-properties"></div>
      )}
      <div className="text-properties-header">
        Text Properties <span className="pro">PRO</span>
      </div>
      <div className="color-pick">
        <div style={{ display: "inline-block" }}>Color </div>
        <div className="color-picker-container">
          <button
            style={{
              backgroundColor: activeComponent
                ? activeComponent.color
                : "#000000",
              opacity: activeComponent ? activeComponent.opacity / 100 : "1",
            }}
            onClick={() => setColorPicker(!colorPicker)}
          ></button>
          <div> {activeComponent ? activeComponent.color : "#000000"}</div>
        </div>
      </div>
      <div className="font-styles">
        <div style={{ display: "inline-flex" }}> Font</div>
        <div className="font-input">
          {/* <input placeholder='Robboto Condensed' /> */}
          <Select
            value={
              activeComponent
                ? activeComponent.fontStyle
                : { value: "Arial", label: "Arial" }
            }
            onChange={handleFontChange}
            options={options}
          />
        </div>
      </div>
      <div className="opacity">
        <div>
          <span>Opacity</span>
          <span style={{ float: "right" }}>
            {activeComponent ? activeComponent.opacity : "100"}
          </span>
        </div>
        <div style={{ marginTop: "18px" }}>
          <Range
            step={1}
            min={0}
            max={100}
            values={activeComponent ? activeComponent.opacity : [100]}
            onChange={(values) => handleActiveOpacity(values)}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "8px",
                  width: "100%",
                  // backgroundColor: activeComponent[0] ? activeComponent[0].color : '#000000',
                  backgroundColor: "#48A629",
                  borderRadius: "100px",
                  opacity: activeComponent
                    ? activeComponent.opacity / 100
                    : "1",
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "20px",
                  width: "20px",
                  backgroundColor: "rgba(240, 240, 240)",
                  boxShadow:
                    "0 -2px 4px 0 rgba(140,140,140,0.4), 0 2px 4px 0 rgba(140,140,140,0.4)",
                  borderRadius: "50%",
                }}
              />
            )}
          />
        </div>
      </div>
      {colorPicker && (
        <Auxilary>
          <Backdrop
            id="overlay__text-properties"
            className={"transparent-backdrop"}
            show={true}
            onClose={() => setColorPicker(false)}
          >
            <ColorPicker
              color={activeComponent ? activeComponent.color : "#000000"}
              styles="color-picker"
              setColor={handleChangeColor}
              closeColorPicker={() => setColorPicker(false)}
            />
          </Backdrop>
        </Auxilary>
      )}
    </div>
  );
};

export default TextProperties;
