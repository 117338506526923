import React from 'react';

import LogoImage from '../assets/images/logos/logoPhraseit.png';

const Logo = props => {
    return (
        <div className={props.styles}>
            <img
                src={LogoImage}
                className={props.imageStyles}
                alt="phrase.it logo" />
        </div>
    )
}

export default Logo;