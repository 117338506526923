import { useDispatch, useSelector } from "react-redux";

///store imports
import { set_bubble } from "../store/actions/rndContainerActions";

//styles
import "../assets/styles/components/_bubbles.scss";
import { Fragment, useState } from "react";

//js components
import ModalPaymentProBubbles from "../UI/ModalPaymentProBubbles";
import ModalCreateLogin from "../UI/ModalCreateLogin";
import Auxilary from "../hoc/Auxilary";
import Backdrop from "../UI/Backdrop";

const Bubbles = (props) => {
  const userPlan = useSelector((state) => state.authReducer.currentUser.is_pro);
  const dispatch = useDispatch();
  const [modalPayment, setModalPayment] = useState(false);
  const [openModal, setOpenModal] = useState({
    open: false,
    type: null,
  });

  const openModalHandler = (type) => {
    setOpenModal({
      open: true,
      type,
    });
    setModalPayment(false);
  };
  const closeModalHandler = () => {
    setOpenModal({
      open: false,
      type: null,
    });
  };

  const handleCreateLoginResponse = () => {
    setOpenModal({
      open: false,
      type: null,
    });
  };

  const closeModalPaymentHandler = () => {
    setModalPayment(false);
  };

  const imageClick = (data) => {
    if (!props.item.pro || userPlan) {
      dispatch(set_bubble(data));
      return;
    }
    if (props.item.pro) {
      setModalPayment(true);
    }
  };

  return (
    <Fragment>
      <div
        className="item-images"
        style={{ backgroundImage: `url(${props.item.variations.right})` }}
        onClick={() => imageClick(props.item)}
      >
        {props.item.pro && <div className="pro">PRO</div>}
      </div>
      {modalPayment && (
        <ModalPaymentProBubbles
          onClose={closeModalPaymentHandler}
          openCreateLogin={() => openModalHandler("create")}
          bubbleImagePath={props.item.variations.right}
        />
      )}
      {openModal.open && (
        <Auxilary>
          <Backdrop show={true} onClose={closeModalHandler}>
            <ModalCreateLogin
              type={openModal.type}
              closeModal={closeModalHandler}
              handleCreateLoginResponse={handleCreateLoginResponse}
              subscribe
            />
          </Backdrop>
        </Auxilary>
      )}
    </Fragment>
  );
};

export default Bubbles;
