export const SET_BUBBLE = "SET_BUBBLE";
export const CLOSE_BUBBLE = "CLOSE_BUBBLE";
export const SET_ACTIVE_FONT = "SET_ACTIVE_FONT";
export const INITIALIZE_BUBBLE_COMPONENT = "INITIALIZE_BUBBLE_COMPONENT";
export const SET_ACTIVE_BUBBLE = "SET_ACTIVE_BUBBLE";
export const SET_ACTIVE_COLOR = "SET_ACTIVE_COLOR";
export const SET_ACTIVE_OPACITY = "SET_ACTIVE_OPACITY";
export const SET_ACTIVE_OPACITY_BUBBLE = "SET_ACTIVE_OPACITY_BUBBLE";
export const SET_BACKGROUND_IMAGE = "SET_BACKGROUND_IMAGE";
export const SET_ROTATION_DEGREE = "SET_ROTATION_DEGREE";
export const ADD_NEW_RND_CONTAINER = "ADD_NEW_RND_CONTAINER";
export const REMOVE_RND_CONTAINER = "REMOVE_RND_CONTAINER";
export const RESET_RND_STATE = 'RESET_RND_STATE';
export const SET_ACTIVE_RND_CONTAINER = "SET_ACTIVE_RND_CONTAINER";
export const SET_OPENED_PRO_BUBBLE = "SET_OPENED_PRO_BUBBLE";
export const INITIALIZE_USER = "INITIALIZE_USER";
export const UPDATE_USER_STATE = "UPDATE_USER_STATE";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_USERNAME = "SET_USERNAME";
export const SET_EMAIL = "SET_EMAIL";
export const INITIALIZE_BUBBLES = "INITIALIZE_BUBBLES";
export const SET_AI_IMAGES_REMAINING = "SET_AI_IMAGES_REMAINING";
