import React from "react";

//styles
import "../assets/styles/containers/_sidebar-right.scss";

const SidebarRight = () => {
  return <div className="sidebar-right"></div>;
};

export default SidebarRight;
