import React, { useState } from "react";

//styles
import "../assets/styles/containers/_sidebar-left.scss";

//components
import Gallery from "../components/Gallery";
import BubblesTab from "../components/BubblesTab";

const SidebarLeft = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  let activeComponent = <Gallery />;
  if (activeTab === 1) activeComponent = <BubblesTab history={props.history} />;

  return (
    <div className="sidebar-left" style={props.style}>
      <div className="sidebar-nav">
        <button
          onClick={() => setActiveTab(0)}
          className={
            activeTab === 0 ? "sidebar-items active-item" : "sidebar-items"
          }
        >
          Images
        </button>
        <button
          onClick={() => setActiveTab(1)}
          className={
            activeTab === 1 ? "sidebar-items active-item" : "sidebar-items"
          }
        >
          Bubbles
        </button>
      </div>

      {activeComponent}
    </div>
  );
};

export default SidebarLeft;
