import "../assets/styles/components/_gallery-image.scss";
import { useEffect, useRef, useState } from "react";

import useHttp from "../hooks/use-http";
import useInput from "../hooks/use-input";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ModalProfile from "../UI/ModalProfile";
import closeGrey from "../assets/images/icons/close_grey.svg";
import { ReactComponent as IconDownload } from "../assets/images/icons/iconDownload.svg";
import { ReactComponent as IconShare } from "../assets/images/icons/iconShare.svg";
import { ReactComponent as IconCorrectGreen } from "../assets/images/icons/iconCorrectGreen.svg";
import { ReactComponent as IconTrash } from "../assets/images/icons/trash.svg";
import { ReactComponent as Spinner } from "../assets/images/icons/spinner.svg";

const GalleryImage = (props) => {
  const timeoutRef = useRef(null);
  const userToken = useSelector((state) => state.authReducer.userToken);
  const userEmail = useSelector((state) => state.authReducer.currentUser.email);
  const [isHoverActionsShown, setIsHoverActionsShow] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const {
    isLoading: isLoadingShare,
    //error: errorShare,
    sendRequest: sendShareRequest,
  } = useHttp();
  const {
    isLoading: isLoadingDelete,
    error: errorDelete,
    sendRequest: sendDeleteRequest,
  } = useHttp();

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset,
  } = useInput((value) =>
    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)
  );

  const closeShareModalHandler = () => {
    setCopied(false);
    setShareModal(false);
  };
  const closeDownloadModalHandler = () => {
    setDownloadModal(false);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const shareImageHandler = () => {
    setShareModal(true);
  };
  const downloadImageHandler = () => {
    setDownloadModal(true);
  };
  const deleteImageHandler = () => {
    setDeleteModal(true);
  };

  const handleDownloadImage = () => {
    props.onSuccessDownload();
    setDownloadModal(false);
    saveAs(props.data.url, "phareseit-collection.jpg");
  };

  const handleSendShareResponse = () => {
    props.onSuccessShare();
    setShareModal(false);
    reset();
  };

  const handleSendDeleteResponse = () => {
    props.onSuccessDelete(props.data.id);
    setDeleteModal(false);
  };

  const handleDeleteImage = () => {
    const formData = new FormData();
    formData.append("image_id", props.data.id);
    sendDeleteRequest(
      {
        url: "/api/collection/delete-image",
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      },
      handleSendDeleteResponse
    );
  };
  const sendEmailHandler = () => {
    const formData = new FormData();
    formData.append("email_from", userEmail);
    formData.append("email_to", emailValue);
    formData.append("image_id", props.data.id);
    sendShareRequest(
      {
        url: "/api/collection/image-share",
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      },
      handleSendShareResponse
    );
  };

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      setCopied(false);
    }, 3500);
    return () => {
      resetTimeout();
    };
  }, [copied]);

  return (
    <div className="user-collection">
      {shareModal && (
        <ModalProfile onClose={closeShareModalHandler}>
          <div className="user-collection-modal-share">
            <div className="user-collection-modal-share__heading">
              <h2>Share Image</h2>
              <button onClick={closeShareModalHandler}>
                <img src={closeGrey} alt="close-icon" />
              </button>
            </div>
            <div className="user-collection-modal-share__container">
              <div className="user-collection-modal-share__image">
                <img src={props.data.url} alt="collection-img" />
              </div>
              <div className="user-collection-modal-share__content">
                <div
                  className={
                    copied
                      ? "user-collection-modal-share__clipboard-container user-collection-modal-share__no-hover"
                      : "user-collection-modal-share__clipboard-container"
                  }
                >
                  <p>Share this image via</p>
                  <span className="tooltiptext">Copy to clipboard</span>

                  <CopyToClipboard
                    text={props.data.share_link}
                    onCopy={() => setCopied(true)}
                  >
                    <button className="user-collection-modal-share__clipboard--btn">
                      Public share link
                    </button>
                  </CopyToClipboard>
                </div>

                {copied && (
                  <div className="user-collection-modal-share__clipboard">
                    <div>
                      <IconCorrectGreen /> <p>Copied to clipboard</p>
                    </div>
                    <span>{props.data.share_link}</span>
                  </div>
                )}

                <div className="user-collection-modal-share__line">
                  <span>or</span>
                </div>
                <p>
                  Share this photo to a friend by entering their email address
                  below
                </p>
                <div className="user-collection-modal-share__input-container">
                  <input
                    type="email"
                    placeholder="Email address"
                    onChange={emailChangeHandler}
                    onBlur={emailBlurHandler}
                    value={emailValue}
                  />
                  {emailHasError && (
                    <span className="user-collection-modal-share__input-error">
                      Please enter valid email
                    </span>
                  )}
                </div>
                <div className="user-collection-modal-share__actions">
                  <button
                    className="user-collection-modal-share__actions--cancel"
                    onClick={closeShareModalHandler}
                  >
                    Cancel
                  </button>
                  {isLoadingShare && <Spinner />}
                  {!isLoadingShare && (
                    <button
                      onClick={sendEmailHandler}
                      className={
                        emailHasError
                          ? "user-collection-modal-share__actions--send-error"
                          : "user-collection-modal-share__actions--send"
                      }
                      disabled={!emailIsValid}
                    >
                      Send Email
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalProfile>
      )}
      {downloadModal && (
        <ModalProfile onClose={closeDownloadModalHandler}>
          <div className="user-collection-modal-download">
            <div className="user-collection-modal-download__image">
              <img src={props.data.url} alt="collection-img" />
            </div>
            <div className="user-collection-modal-download__content">
              <div className="user-collection-modal-download__content--heading">
                <IconDownload />
                <h2>Download your image</h2>
              </div>
              <p>By proceeding you agree to our Terms of Service</p>
              <p>
                You confirm that no copyrights are violated and this photo is
                not of defamatory or libelous nature
              </p>
              <div className="user-collection-modal-download__actions">
                <button
                  className="user-collection-modal-download__actions--cancel"
                  onClick={closeDownloadModalHandler}
                >
                  Cancel
                </button>
                <button
                  className="user-collection-modal-download__actions--send"
                  onClick={handleDownloadImage}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </ModalProfile>
      )}
      {deleteModal && (
        <ModalProfile onClose={closeDeleteModal}>
          <div className="user-collection-modal-download">
            <div className="user-collection-modal-download__image">
              <img src={props.data.url} alt="collection-img" />
            </div>
            <div className="user-collection-modal-download__content">
              <div className="user-collection-modal-download__content--heading">
                <IconTrash />
                <h2>Delete your image</h2>
              </div>
              <p>Are you sure you want to delete this image?</p>
              <p>
                Once you delete this image is gone forever. This action cannot
                be undone.
              </p>
              <div className="user-collection-modal-download__actions">
                <button
                  className="user-collection-modal-download__actions--cancel"
                  onClick={closeDeleteModal}
                >
                  Cancel
                </button>
                {isLoadingDelete && <Spinner />}
                {!isLoadingDelete && (
                  <button
                    className="user-collection-modal-download__actions--send"
                    onClick={handleDeleteImage}
                  >
                    Delete
                  </button>
                )}
              </div>
              {errorDelete && (
                <p className="user-collection-modal-download--error">
                  {errorDelete}
                </p>
              )}
            </div>
          </div>
        </ModalProfile>
      )}
      <img
        src={props.data.url}
        alt="collection-img"
        className="user-collection__image"
      />

      <div
        className="user-collection__container"
        onMouseEnter={() => setIsHoverActionsShow(true)}
        onMouseLeave={() => setIsHoverActionsShow(false)}
        onClick={() => setIsHoverActionsShow(true)}
      >
        {isHoverActionsShown && (
          <div className="user-collection__hover">
            <div className="user-collection__hover--content">
              <button onClick={shareImageHandler}>
                <IconShare />
              </button>
              <button onClick={downloadImageHandler}>
                <IconDownload />
              </button>
              <button onClick={deleteImageHandler}>
                <IconTrash />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GalleryImage;
