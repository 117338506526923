import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { createStore, compose, combineReducers } from 'redux';


import './index.scss';
import App from './App';
import sideBarBubblesReducer from './store/reducers/sideBarBubblesReducer';
import stylesReducer from './store/reducers/stylesReducer';
import rndContainersReducer from './store/reducers/rndContainersReducer';
import authReducer from './store/reducers/authReducer';
// import backgroundImagesReducer from './store/reducers/backgroundImagesReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  sideBarBubblesReducer: sideBarBubblesReducer,
  stylesReducer,
  rndContainersReducer,
  authReducer
});

// const store = createStore(rootReducer);
const store = createStore(rootReducer, composeEnhancers());



ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Route path='/' component={App}/>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

