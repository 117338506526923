import { Fragment } from "react";
import ReactDOM from "react-dom";

import "../assets/styles/UI/_modal-profile.scss";

const Backdrop = (props) => {
  return <div className="backdrop-profile" onClick={props.onClose} />;
};

const ModalOverlay = (props) => {
  return (
    <div className={props.padding ? 'modal-profile modal-profile__padding' : 'modal-profile'}>
      <div className="modal-profile__content">{props.children}</div>
    </div>
  );
};

const portalElement = document.getElementById("overlays");
const ModalProfile = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay padding={props.padding}>{props.children}</ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default ModalProfile;
