import { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router";
import { update_user_state } from "../store/actions/authActions";
import useHttp from "../hooks/use-http";
import ModalProfile from "../UI/ModalProfile";
import Homepage from "./Homepage";
import Spinner from "../UI/Spinner";
import closeGrey from "../assets/images/icons/close_grey.svg";
import { ReactComponent as IconProUser } from "../assets/images/icons/iconProUser.svg";
import "../assets/styles/pages/_homepage-payment.scss";

const HomepagePayment = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const session_id = new URLSearchParams(search).get("session_id");
  const [successModal, setSuccessModal] = useState(false);
  const userToken = useSelector((state) => state.authReducer.userToken);
  const { isLoading, error, sendRequest: sendCheckSessionId } = useHttp();

  const handleCheckSessionIdResponse = useCallback(
    (response) => {
      if (response.paid === response.session_exists) {
        dispatch(update_user_state(response.user, userToken));
        setSuccessModal(true);
      } else {
        history.replace("/");
      }
    },
    [dispatch, userToken, history]
  );

  const closeSuccessModal = () => {
    setSuccessModal(false);
    history.replace("/");
  };

  useEffect(() => {
    if (session_id) {
      sendCheckSessionId(
        {
          url: `/api/user/check-session-id?session_id=${session_id}`,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        },
        handleCheckSessionIdResponse
      );
    } else {
      history.replace("/");
    }
  }, [
    sendCheckSessionId,
    handleCheckSessionIdResponse,
    userToken,
    session_id,
    history,
  ]);
  return (
    <Fragment>
      {isLoading && (
        <div className="loading">
          <Spinner spinnerStyle="lds-ripple" />
        </div>
      )}

      {successModal && (
        <ModalProfile onClose={closeSuccessModal}>
          <div className="modal-success-payment">
            <div className="modal-success-payment__title">
              <div>Payment Successful</div>
              <button onClick={closeSuccessModal}>
                <img src={closeGrey} alt="close-icon" />
              </button>
            </div>
            <div className="modal-success-payment__description-part">
              <IconProUser />
              <div className="modal-success-payment__description-text">
                <p>
                  Your payment has been successfuly completed. Now you are PRO
                  user and you can proceed with creating your images.
                </p>
              </div>
            </div>
            <button
              className="modal-success-payment__btn"
              onClick={closeSuccessModal}
            >
              Okay
            </button>
          </div>
        </ModalProfile>
      )}
      {error && (
        <div className="homepage-payment-error">
          <div>
            <p>{error}</p>
            <button onClick={() => history.replace("/")}>Go Back</button>
          </div>
        </div>
      )}
      {!error && <Homepage />}
    </Fragment>
  );
};

export default HomepagePayment;
