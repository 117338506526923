import React from "react";
import useHttp from "../hooks/use-http";
import { useState } from "react";
import useInput from "../hooks/use-input";

//styles
import "../assets/styles/UI/_modal-create-login.scss";

//icons
import closeGrey from "../assets/images/icons/close_grey.svg";
import { ReactComponent as Spinner } from "../assets/images/icons/spinner.svg";

const ModalCreateLogin = (props) => {
  const [modalType, setModalType] = useState(props.type);
  const {
    isLoading: isLoadingLogin,
    error: errorLoginRequest,
    sendRequest: sendLoginRequest,
  } = useHttp();
  const {
    isLoading: isLoadingCreate,
    error: errorCreateRequest,
    sendRequest: sendCreateRequest,
  } = useHttp();
  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset,
  } = useInput((value) =>
    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)
  );

  const openModalHandler = () => {
    if (modalType === "create") {
      setModalType("login");
      reset();
    } else {
      setModalType("create");
      reset();
    }
  };

  const loginCreateUserHandler = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("email", emailValue);
    formData.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
    formData.append("type", props.subscribe ? 3 : 0);

    if (modalType === "create") {
      sendCreateRequest(
        {
          url: "/api/register",
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        },
        props.handleCreateLoginResponse
      );
    } else {
      sendLoginRequest(
        {
          url: "/api/login-api",
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        },
        props.handleCreateLoginResponse
      );
    }
  };

  return (
    <div className="modal-create-login">
      <div className="title">
        <div>{modalType === "create" ? "Create Account" : "Login"}</div>
        <button onMouseDown={() => {props.closeModal()}}>
          <img src={closeGrey} alt="close-icon" />
        </button>
      </div>
      <div className="description-part">
        <div className="description-text">
          {modalType === "create" ? (
            <span>
              {props.subscribe
                ? "In order to became pro user, "
                : "In order to add images in your personal collection, "}
              we should create you an account.
              <br />
              <br />
              Please add your email and we will send you a magic link so you can
              log in.
            </span>
          ) : (
            <span>
              Please add your email and we will send you a magic link so you can
              log in.
            </span>
          )}
        </div>
        <form onSubmit={loginCreateUserHandler}>
        <input
          placeholder="E-Mail Address"
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
          value={emailValue}
        />
        </form>

        {emailHasError && (
          <p className="modal-create-login__error">Please enter valid email.</p>
        )}
        {errorLoginRequest && modalType === "login" && (
          <p className="modal-create-login__error">
            This email doesn't match any account.
          </p>
        )}
        {errorCreateRequest && modalType === "create" && (
          <p className="modal-create-login__error">
            This email is connected to account please login
          </p>
        )}
        <div className="modal-cancel-create-buttons">
          {(isLoadingLogin || isLoadingCreate) && <Spinner />}
          {!isLoadingCreate && modalType === "create" && (
            <button
              className={emailHasError ? "create create-not-valid" : "create"}
              onClick={loginCreateUserHandler}
              disabled={!emailIsValid}
              type='submit'
            >
              Create
            </button>
          )}
          {!isLoadingLogin && modalType === "login" && (
            <button
              className={emailHasError ? "create create-not-valid" : "create"}
              onClick={loginCreateUserHandler}
              disabled={!emailIsValid}
              type='submit'
            >
              Login
            </button>
          )}
        </div>
        {modalType === "create" ? (
          <div className="modal-create-login__actions">
            <span>Existing User?</span>
            <button onClick={openModalHandler}>Log in</button>
          </div>
        ) : (
          <div className="modal-create-login__actions">
            <span>New User?</span>
            <button onClick={openModalHandler}>Sign Up</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalCreateLogin;
