import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import ModalProfile from "../UI/ModalProfile";
import ModalPaymentProBubbles from "../UI/ModalPaymentProBubbles";
import ModalCreateLogin from "../UI/ModalCreateLogin";

//styles
import "../assets/styles/components/_gallery.scss";

//icons and logos
import { ReactComponent as IconAlert } from "../assets/images/icons/iconAlert.svg";
import searchIcon from "../assets/images/icons/search.svg";
import pixabayLogo from "../assets/images/logos/logoPixabay.svg";
import Spinner from "../UI/Spinner";

//reducer actions
import { set_background_image } from "../store/actions/backgroundImageActions";
import { set_ai_images_remaining } from "../store/actions/authActions";
import useHttp from "../hooks/use-http";
import Auxilary from "../hoc/Auxilary";
import Backdrop from "../UI/Backdrop";

const Gallery = (props) => {
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.authReducer.userToken);
  const [searchValue, setSearchValue] = useState("");
  const [aiImgPrompt, setAiImgPrompt] = useState("");
  const [modalError, setModalError] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [pixabayImages, setPixabayImages] = useState([]);
  const [loadingImages, setLoadingImages] = useState(true);
  //const [loadingAiImg, setLoadingAiImg] = useState(false);
  const [componentMounted, setComponentMounted] = useState(null);
  const [nextPage, setNextPage] = useState(1);
  const refTolast = useRef();
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const aiImagesLeft = useSelector(
    (state) => state.authReducer.currentUser.ai_images_left
  );
  const userIsPro = useSelector(
    (state) => state.authReducer.currentUser.is_pro
  );
  const { isLoading, error, sendRequest: sendGenerateImgRequest } = useHttp();
  const [modalPayment, setModalPayment] = useState(false);
  const [openModal, setOpenModal] = useState({
    open: false,
    type: null,
  });
  //const [firstImageLoad, setFirstImageLoad] = useState(null);

  const setActiveBackground = (image, height) => {
    dispatch(set_background_image(image, height));
  };

  const imageIsLoaded = async (e) => {
    /*if(process.env.REACT_APP_SIGHTENGINE_USER) {
      const form = new FormData();
      var url = "https://api.sightengine.com/" + "1.0/check.json";

      let arr = e.target.result.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[arr.length - 1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      form.append("api_user", process.env.REACT_APP_SIGHTENGINE_USER);
      form.append("api_secret", process.env.REACT_APP_SIGHTENGINE_SECRET);
      form.append("models", "nudity-2.0");
      form.append(
          "media",
          new File([u8arr], Math.random().toString(36).slice(-5), { type: mime })
      );

      try {
        const response = await fetch(url, {
          method: "POST",
          body: form,
        });

        if (!response.ok) {
          const data = await response.json();
          throw Error(data.message);
        }

        const data = await response.json();

        if (data.status === "success" && data.nudity.none >= 0.5) {

        } else {
          throw Error("Nude images are not allowed!");
        }
      } catch (err) {
        setModalError(true);
      }
    }*/
    // Create a new Image object
    const img = new Image();
    img.onload = function () {
      // Get the natural width and height of the image
      const naturalWidth = this.naturalWidth;
      const naturalHeight = this.naturalHeight;

      // Calculate the aspect ratio of the image
      const aspectRatio = naturalWidth / naturalHeight;

      const element = document.getElementById("containers-wrapper-image");

      // Calculate the height for the fixed width while maintaining the aspect ratio
      const fixedWidth = element?.clientWidth || 500;

      const calculatedHeight = fixedWidth / aspectRatio;

      dispatch(
          set_background_image(`url(${e.target.result})`, calculatedHeight)
      );
    };
    img.src = e.target.result;
  };

  const uploadImageHandler = (event) => {
    var reader = new FileReader();
    reader.onload = imageIsLoaded;
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleGenerateImgResponse = (response) => {
    let url = response.url;
    dispatch(set_ai_images_remaining(response.ai_images_left));
    console.log(response);

    // Create a new Image object
    const img = new Image();
    img.onload = function () {
      // Get the natural width and height of the image
      const naturalWidth = this.naturalWidth;
      const naturalHeight = this.naturalHeight;

      // Calculate the aspect ratio of the image
      const aspectRatio = naturalWidth / naturalHeight;

      const element = document.getElementById("containers-wrapper-image");

      // Calculate the height for the fixed width while maintaining the aspect ratio
      const fixedWidth = element?.clientWidth || 500;

      const calculatedHeight = fixedWidth / aspectRatio;

      dispatch(set_background_image(`url(${url})`, calculatedHeight));
    };
    img.src = url;

    setAiImgPrompt("");
    document.getElementById("aiImgPrompt").style.display = "inline-block";
    document.getElementById("generateImgBtn").style.display = "inline-block";
  };

  const generateImage = (event) => {
    document.getElementById("aiImgPrompt").style.display = "none";
    document.getElementById("generateImgBtn").style.display = "none";
    console.log(aiImgPrompt);

    const formData = new FormData();
    formData.append("prompt", aiImgPrompt);
    sendGenerateImgRequest(
      {
        url: "/api/image/generate-ai-image",
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      },
      handleGenerateImgResponse
    );
  };

  const handlePromptChange = (event) => {
    setAiImgPrompt(event.target.value);
  };

  useEffect(() => {
    if (error) {
      setAiImgPrompt("");
      document.getElementById("aiImgPrompt").style.display = "inline-block";
      document.getElementById("generateImgBtn").style.display = "inline-block";
    }
  }, [error]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue.trim().length >= 1) {
        //const configs = getPixibyImages();

        setLoadingImages(true);
        setNextPage(1);
        axios
          .get(
            process.env.REACT_APP_PIXABAY_API +
              "?key=" +
              process.env.REACT_APP_PIXABAY_API_KEY +
              "&q=" +
              searchValue +
              "&safesearch=true&page=1&min_width=640",
            {}
          )
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              setNextPage(2);
              setPixabayImages(response.data.hits);
              setLoadingImages(false);
              // refTolast.current.scrollTo(0, 0);
            }
            setScrolling(false);
          })
          .catch((err) => {
            setScrolling(false);
            setLoadingImages(false);
          });
      } else {
        axios
          .get(
            process.env.REACT_APP_PIXABAY_API +
              "?key=" +
              process.env.REACT_APP_PIXABAY_API_KEY +
              "&q=" +
              searchValue +
              "&safesearch=true&page=1&min_width=640",
            {}
          )
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              setNextPage(2);
              setPixabayImages(response.data.hits);
              // if (!firstImageLoad) {
              //     setFirstImageLoad(true);
              //     dispatch(set_background_image(`url(${response.data.hits[0].webformatURL})`));
              // }
            }
            setLoadingImages(false);
            setScrolling(false);
          })
          .catch((err) => {
            setScrolling(false);
            setLoadingImages(false);
          });
      }
    }, 800);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  const handleCreateLoginResponse = () => {
    setOpenModal({
      open: false,
      type: null,
    });
  };

  const openModalHandler = (type) => {
    setOpenModal({
      open: true,
      type,
    });
    setModalPayment(false);
  };
  const closeModalHandler = () => {
    setOpenModal({
      open: false,
      type: null,
    });
  };

  const closeModalPaymentHandler = (event) => {
    event?.stopPropagation();
    setModalPayment(false);
  };

  const subscribeHandler = () => {
    if (
      isLoggedIn &&
      !userIsPro &&
      !aiImagesLeft &&
      !modalPayment &&
      !openModal.open
    ) {
      setModalPayment(true);
    }

    if (!isLoggedIn && !modalPayment && !openModal.open) {
      setOpenModal({
        open: true,
        type: "create",
      });
      setModalPayment(false);
    }
  };

  const handleScroll = (e) => {
    if (scrolling) return;
    if (!nextPage) return;
    if (!refTolast.current) return;
    if (refTolast.current.childNodes.length === 0) return;
    if (loadingImages) return;
    // const elem = refTolast.current.childNodes[refTolast.current.childNodes.length - 1];
    // const lastLioffset = elem.offsetTop + elem.clientHeight;
    // const pageOffset = window.pageYOffset + window.innerHeight;
    // var bottomoffset = 300;
    // const scrollingPosition = refTolast.current.scrollTop
    if (refTolast.current.scrollTop + 800 > +refTolast.current.scrollHeight) {
      setScrolling(true);
    }
  };
  useEventListener("scroll", handleScroll);

  useEffect(() => {
    if (scrolling && nextPage && componentMounted && !loadingImages) {
      // const configs = getPixibyImages();

      axios
        .get(
          process.env.REACT_APP_PIXABAY_API +
            "?key=" +
            process.env.REACT_APP_PIXABAY_API_KEY +
            "&q=" +
            searchValue +
            "&safesearch=true&page=" +
            nextPage +
            "&min_width=640",
          {}
        )
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setNextPage(nextPage + 1);
            setPixabayImages(pixabayImages.concat(response.data.hits));
          }
          setScrolling(false);
        })
        .catch((err) => {
          setScrolling(false);
        });
    } else {
      setComponentMounted(true);
    }
  }, [scrolling]);

  let mapingImages = null;
  if (pixabayImages.length !== 0) {
    mapingImages = pixabayImages.map((item) => {
      return (
        <div
          key={item.id}
          className="image-option"
          style={{ backgroundImage: `url(${item.webformatURL})` }}
          onClick={() =>
            setActiveBackground(
              `url(${item.webformatURL})`,
              item.webformatHeight
            )
          }
        ></div>
      );
    });
  } else {
    mapingImages = <div className="no-results">No results found</div>;
  }
  // } else {
  //     mapingImages = images.map(item => {
  //         return <div
  //             key={item}
  //             className='image-option'
  //             style={{ backgroundImage: `url(/background-images/${item})` }}
  //             onClick={() => setActiveBackground(`url(/background-images/${item})`)}
  //         >
  //         </div>
  //     })
  // }

  return (
    <div className="gallery">
      {modalError && (
        <ModalProfile onClose={props.onClose}>
          <div className="modal-image-error">
            <h1>
              This image very likely contains adult content which goes against
              our terms and conditions
            </h1>
            <div className="modal-image-error__icon">
              <IconAlert />
            </div>

            <button onClick={() => setModalError(false)}>Close</button>
          </div>
        </ModalProfile>
      )}

      <div className="sidebar-image-options">
        <div className="upload">
          <input
            id="imageUpload"
            type="file"
            accept="image/*"
            onChange={uploadImageHandler}
            hidden
          />
          <label htmlFor="imageUpload">Upload Image</label>
        </div>
      </div>
      <div className="upload-or__line">
        <span>or</span>
      </div>
      <div className="ai-img-wrapper" onClick={subscribeHandler}>
        {!isLoggedIn || (isLoggedIn && aiImagesLeft == 0 && !userIsPro) ? (
          <div className="hide-styles-properties"></div>
        ) : null}
        <div className="ai-img-title">Generate AI Image</div>
        <div className="ai-img-remaining">
          *
          {isLoggedIn ? (
            userIsPro ? (
              <span>
                You have <span className="ai-img-number">{aiImagesLeft}</span>{" "}
                AI images left for this month.
              </span>
            ) : aiImagesLeft ? (
              <span>
                You have <span className="ai-img-number">{aiImagesLeft}</span>{" "}
                AI images left.
              </span>
            ) : (
              <span>
                You have <span className="ai-img-number">{aiImagesLeft}</span>{" "}
                AI images left. Upgrade to a Pro user to generate more AI
                images.
              </span>
            )
          ) : (
            <span>Login to generate AI image.</span>
          )}
        </div>
        <textarea
          rows="4"
          maxLength="1000"
          className="ai-img-prompt"
          id="aiImgPrompt"
          disabled={!isLoggedIn || !aiImagesLeft}
          value={aiImgPrompt}
          onChange={handlePromptChange}
          placeholder="Enter your prompt to generate AI image..."
        ></textarea>
        {isLoading && <Spinner spinnerStyle="loader" />}
        {error && <p className="ai-img-wrapper__error">{error}</p>}
        <div className="ai-img-btn-wrapper">
          <button
            className="ai-img-generate-btn"
            id="generateImgBtn"
            disabled={!isLoggedIn || !aiImagesLeft}
            onClick={generateImage}
          >
            Generate
          </button>
        </div>
      </div>
      <div className="upload-or__line">
        <span>or</span>
      </div>
      <div className="search-image">
        <input
          placeholder="Search stock images"
          value={searchValue}
          onChange={(event) => handleSearchChange(event)}
        ></input>
        <button>
          <img src={searchIcon} alt="search" />
        </button>
      </div>
      <div className="powered-by">
        <a
          href="https://pixabay.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          powered by <img src={pixabayLogo} alt="pixabay logo" />
        </a>
      </div>
      <div className="images" ref={refTolast}>
        {loadingImages ? <Spinner spinnerStyle="loader" /> : mapingImages}
      </div>
      {modalPayment && (
        <ModalPaymentProBubbles
          onClose={closeModalPaymentHandler}
          openCreateLogin={() => openModalHandler("create")}
          bubbleImagePath={null}
        />
      )}
      {openModal.open && (
        <Auxilary>
          <Backdrop show={true} onClose={closeModalHandler}>
            <ModalCreateLogin
              type={openModal.type}
              closeModal={closeModalHandler}
              handleCreateLoginResponse={handleCreateLoginResponse}
            />
          </Backdrop>
        </Auxilary>
      )}
    </div>
  );
};

export default Gallery;

function useEventListener(eventName, handler, element = window) {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event) => savedHandler.current(event);

    element.addEventListener(eventName, eventListener, true);

    return () => {
      element.removeEventListener(eventName, eventListener, true);
    };
  }, [eventName, element]);
}
