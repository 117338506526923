import React from "react";
import { ReactComponent as IconUploadImage } from "../assets/images/icons/iconUploadImage.svg";

export const NewRndBubbleContainer = (props) => {
  return (
    <div
      ref={props.refToComponent}
      className={
        props.active ? "active-container new-rnd-wrapper" : "new-rnd-wrapper"
      }

      onTouchStart={() => props.onTouchStartProp()}
      onClick={() => props.onClickProp()}
    >
      <div>
        <div>
          <IconUploadImage />
        </div>
        <p>
          You can add image by chosing from your device or you can choose from
          our collection of images in the left panel
        </p>
      </div>
    </div>
  );
};

export default NewRndBubbleContainer;
