import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { useDispatch } from "react-redux";
import useHttp from "../hooks/use-http";
import { Link } from "react-router-dom";
import { logout_user } from "../store/actions/authActions";
import { reset_rnd_state } from "../store/actions/rndContainerActions";
import "../assets/styles/UI/_mobile-menu-user.scss";
import ButtonBlack from "./ButtonBlack";
import { ReactComponent as IconMyProfile } from "../assets/images/icons/iconMyProfile.svg";
import { ReactComponent as IconMyProfileSelected } from "../assets/images/icons/iconMyProfileSelected.svg";
import { ReactComponent as IconMyCollection } from "../assets/images/icons/iconMyCollection.svg";
import { ReactComponent as IconMyCollectionSelected } from "../assets/images/icons/iconMyCollectionSelected.svg";
import { ReactComponent as IconBillingHistroy } from "../assets/images/icons/iconBillingHistory.svg";
import { ReactComponent as IconCreate } from "../assets/images/icons/iconCreate.svg";
import { ReactComponent as IconLogout } from "../assets/images/icons/iconLogout.svg";
import { ReactComponent as Spinner } from "../assets/images/icons/spinner.svg";

const MobileMenuUser = (props) => {
  const { userName, userEmail, userToken } = useSelector((state) => {
    return {
      userName: state.authReducer.currentUser.name,
      userEmail: state.authReducer.currentUser.email,
      userToken: state.authReducer.userToken,
    };
  });

  const dispatch = useDispatch();
  const { isLoading, sendRequest: sendLogoutRequest } = useHttp();
  const {
    isLoading: isLoadingBilling,
    error: errorBilling,
    sendRequest: sendBillingPortalRequest,
  } = useHttp();
  const matchProfile = useRouteMatch("/profile");
  const matchCollections = useRouteMatch("/collections");
  let classNameProfile = matchProfile ? "mobile-menu__user-links--active" : "";
  let classNameCollections = matchCollections
    ? "mobile-menu__user-links--active"
    : "";
  const history = useHistory();

  const createImageHandler = () => {
    if(props.onClose){
      props.onClose();
    }
    dispatch(reset_rnd_state());
    history.push('/');
  }
  const handleLogoutResponse = () => {
    dispatch(logout_user());
    if(props.onClose){
      props.onClose();
    }
    history.push("/");
  };

  const logoutHandler = () => {
    sendLogoutRequest(
      {
        url: "/api/logout",
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      },
      handleLogoutResponse
    );
  };
  const handleBillingPortalResponse = (response) => {
    window.location.href = response.billing_portal_url;
  };
  const billingPortalHandler = () => {
    sendBillingPortalRequest(
      {
        url: "/api/user/billing-portal",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      },
      handleBillingPortalResponse
    );
  };

  return (
    <div className="mobile-menu__user">
      <div className="mobile-menu__user-data">
        <h3>{userName ? userName : "Username"}</h3>
        <p>{userEmail}</p>
      </div>
      <div className="mobile-menu__user-links">
        <Link to="/profile" className={classNameProfile}>
          {matchProfile ? <IconMyProfileSelected /> : <IconMyProfile />}
          My Profile
        </Link>
        <Link to="/collections" className={classNameCollections}>
          {matchCollections ? (
            <IconMyCollectionSelected />
          ) : (
            <IconMyCollection />
          )}
          My Collection
        </Link>
        {isLoadingBilling && <Spinner className="mobile-menu__user-spinner" />}
        {!isLoadingBilling && (
          <button onClick={billingPortalHandler}>
            <IconBillingHistroy />
            Billing Details
          </button>
        )}
        {errorBilling && (
          <p className="mobile-menu__user-error">{errorBilling}</p>
        )}
        {isLoading && <Spinner className="mobile-menu__user-spinner" />}
        {!isLoading && (
          <button onClick={logoutHandler}>
            <IconLogout />
            Logout
          </button>
        )}
      </div>
      <ButtonBlack
        onClick={createImageHandler}
      >
        <IconCreate />
        Create Image
      </ButtonBlack>
    </div>
  );
};

export default MobileMenuUser;
