import React, { useRef, useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";

//styles
import "../assets/styles/containers/_middle-bar.scss";

//containers and components imports
import RndBubblesContainer from "./RndBubblesContainer";
import MiddleTopHeader from "../components/MiddleTopHeader";
import ShareOptionsHeader from "../components/ShareOptionsHeader";
import ModalSaveShareDown from "../UI/ModalSaveShareDown";
import Auxilary from "../hoc/Auxilary";
import Backdrop from "../UI/Backdrop";
import Spinner from "../UI/Spinner";
import Message from "../UI/Message";

import { useScreenshot } from "../hooks/use-screenshot";

const MiddleBar = () => {
  const captureRef = useRef(null);
  // Check if it's the first time the user takes a screenshot on Safari
  const isSafariFirstScreenshot = useRef(true);

  const [clickTriggered, setClickTriggered] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const containers = useSelector(
    (state) => state.rndContainersReducer.containers
  );
  const activeContainer = useSelector(
    (state) => state.rndContainersReducer.activeRndContainerId
  );
  const shareOptionsDisabled = containers[0].activeImage;

  const [openModal, setOpenModal] = useState({
    open: false,
    type: null,
  });
  // method with react screenshot hook
  const { image, takeScreenshot, clear, isLoading } = useScreenshot({
    ref: captureRef,
  });

  const onSuccessHandler = (value) => {
    setSuccessMessage(value);
  };
  const closeMessageHandler = () => {
    setSuccessMessage(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccessMessage(false);
    }, [4000]);
  }, [successMessage]);

  useEffect(() => {
    if (clickTriggered && clickTriggered.clicked) getImage(clickTriggered.type);
  }, [clickTriggered]);

  const getImage = async (type) => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    // Double screenshot for Safari
    if (isSafari && isSafariFirstScreenshot.current) {
      isSafariFirstScreenshot.current = false;
      await takeScreenshot("jpg");
    }

    await takeScreenshot("jpg");

    setOpenModal({
      open: true,
      type,
    });
  };
  const closingModalHandler = () => {
    //clear on react screenshot hook
    clear();
    setOpenModal({
      open: false,
      type: null,
    });
    setClickTriggered({
      clicked: false,
      type: null,
    });
  };
  return (
    <Fragment>
      <div className="middle-bar">
        <div className="middle-bar-wrapper">
          <MiddleTopHeader />
          {successMessage && (
            <Message
              text={successMessage}
              type={true}
              onClose={closeMessageHandler}
            />
          )}
          <ShareOptionsHeader
            clicked={(type) =>
              setClickTriggered({
                clicked: true,
                type,
              })
            }
            disabled={shareOptionsDisabled ? false : true}
          />
          <div className="containers-wrapper">
            <div
              className="containers-wrapper__image"
              id="containers-wrapper-image"
              ref={captureRef}
            >
              {containers.map((item, index) => {
                return (
                  <RndBubblesContainer
                    key={item.id}
                    last={index === containers.length - 1}
                    id={item.id}
                    active={
                      activeContainer === item.id &&
                      !(clickTriggered && clickTriggered.clicked)
                    }
                    clickTriggered={clickTriggered && clickTriggered.clicked}
                    data={item}
                    isLoading={isLoading}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {!isLoading && openModal.open && (
          <Auxilary>
            <Backdrop show={true} onClose={() => closingModalHandler()} />
            <ModalSaveShareDown
              image={image}
              closeModal={() => closingModalHandler()}
              type={openModal.type}
              onSuccess={onSuccessHandler}
            />
          </Auxilary>
        )}
      </div>
      {isLoading && (
        <div className="loading">
          <Spinner spinnerStyle="lds-ripple" />
        </div>
      )}
    </Fragment>
  );
};

export default MiddleBar;
