import '../assets/styles/UI/_button-black.scss';

const ButtonBlack = (props) => {
    return(
        <button onClick={props.onClick} disabled={props.disabled} className="btn__black">
        {props.children}
        </button>
    )
}

export default ButtonBlack;