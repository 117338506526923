import "../assets/styles/pages/_profile.scss";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { set_username, set_email } from "../store/actions/authActions";
import { ReactComponent as Spinner } from "../assets/images/icons/spinner.svg";
import { ReactComponent as IconProUser } from "../assets/images/icons/iconProUser.svg";
import ProfileContainer from "../containers/ProfileContainer";
import ButtonBlack from "../UI/ButtonBlack";
import ModalPaymentProBubbles from "../UI/ModalPaymentProBubbles";
import ModalProfile from "../UI/ModalProfile";
import closeGrey from "../assets/images/icons/close_grey.svg";
import useHttp from "../hooks/use-http";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const [modalPayment, setModalPayment] = useState(false);
  const {
    userName,
    userEmail,
    userToken,
    userPlan,
    subscriptionEndsOn,
    userPlanType,
  } = useSelector((state) => {
    return {
      userName: state.authReducer.currentUser.name,
      userEmail: state.authReducer.currentUser.email,
      userToken: state.authReducer.userToken,
      userPlan: state.authReducer.currentUser.is_pro,
      subscriptionEndsOn: state.authReducer.currentUser.subscription_ends_on,
      userPlanType: state.authReducer.currentUser.payment_type_id,
    };
  });

  const {
    isLoading: isLoadingSubscription,
    error: errorSubscription,
    sendRequest: sendSubscriptionPortalRequest,
  } = useHttp();
  const {
    isLoading,
    error,
    sendRequest: sendEditUsernameEmailRequest,
  } = useHttp();
  const [modalEdit, setModalEdit] = useState({ state: false, type: null }); // type 1 = userName type = 2 email
  const [inputValue, setInputValue] = useState();

  const closeModalPaymentHandler = (event) => {
    setModalPayment(false);
  };
  const closeEditModalHandler = () => {
    setModalEdit({ state: false, type: null });
    setInputValue("");
  };

  const editEmailHandler = () => {
    setModalEdit({ state: true, type: 2 });
  };
  const editUsernameHandler = () => {
    setModalEdit({ state: true, type: 1 });
  };
  const inputChangeHandler = (event) => {
    setInputValue(event.target.value);
  };

  const handleEditUsernameEmailResponse = () => {
    if (modalEdit.type === 1) {
      dispatch(set_username(inputValue));
      closeEditModalHandler();
    } else {
      dispatch(set_email(inputValue));
      closeEditModalHandler();
    }
  };
  const editUsernameMailHandler = () => {
    const formData = new FormData();
    const typeName = modalEdit.type === 1 ? "name" : "email";
    formData.append(typeName, inputValue);
    sendEditUsernameEmailRequest(
      {
        url: `/api/user/edit-${typeName}`,
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      },
      handleEditUsernameEmailResponse
    );
  };

  const handleSubscriptionPortalResponse = (response) => {
    window.location.href = response.billing_portal_url;
  };
  const cancelSubscriptionPortal = () => {
    sendSubscriptionPortalRequest(
      {
        url: "/api/user/billing-portal",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      },
      handleSubscriptionPortalResponse
    );
  };

  const becomeProHandler = () => {
    setModalPayment(true);
  };

  return (
    <ProfileContainer>
      <div className="my-profile">
        <div>
          <h1> My Profile</h1>
        </div>
        <div className="my-profile__nav">
          <div>
            <h3>Personal Information</h3>
          </div>
        </div>

        {!userPlan ? (
          <div className="my-profile__user-type">
            <h4>You are BASIC user</h4>
            <ButtonBlack onClick={becomeProHandler}>
              Become a <span>&nbsp;PRO</span>
            </ButtonBlack>
            {modalPayment && (
              <ModalPaymentProBubbles
                onClose={closeModalPaymentHandler}
                bubbleImagePath={null}
              />
            )}
          </div>
        ) : ((userPlanType !== 3) ? (
                <div className="my-profile__user-type--pro-container">
                  <div className="my-profile__user-type--pro">
                    <div>
                      <IconProUser />
                      <div className="my-profile__user-type--pro-content">

                        <h4>You are PRO user</h4>
                        <p>You will be charged {userPlanType === 1 ? '$1.99 every month.' : '$4.99 every year.'}</p>
                        <p>
                          Your plan will automatically renew unless cancelled before
                          the renewal date.
                        </p>
                        {subscriptionEndsOn && (
                            <p className="my-profile__user-type--pro-content-ends">
                              Your trial ends on {subscriptionEndsOn}
                            </p>
                        )}
                      </div>
                    </div>

                    {isLoadingSubscription && (
                        <Spinner className="my-profile__user-type--pro-spinner" />
                    )}
                    {!isLoadingSubscription && (
                        <ButtonBlack onClick={cancelSubscriptionPortal}>
                          {subscriptionEndsOn ? "Renew plan" : "Cancel Subscription"}
                        </ButtonBlack>
                    )}
                  </div>
                  {errorSubscription && (
                      <p className="my-profile__user-type--pro-error">
                        {errorSubscription}
                      </p>
                  )}
                </div>
            ) : (
                <div className="my-profile__user-type--pro-container">
                  <div className="my-profile__user-type--pro">
                    <div>
                      <IconProUser />
                      <div className="my-profile__user-type--pro-content">

                        <h4>You are a Lifetime PRO user</h4>
                      </div>
                    </div>
                  </div>
                </div>
            )

        )}

        {modalEdit.state && (
          <ModalProfile onClose={closeEditModalHandler}>
            <div className="my-profile-edit-modal">
              <div className="my-profile-edit-modal__heading">
                <h2>Edit {modalEdit.type === 1 ? "username" : "email"}</h2>
                <button onClick={closeEditModalHandler}>
                  <img src={closeGrey} alt="close-icon" />
                </button>
              </div>
              <div className="my-profile-edit-modal__content">
                <p>
                  Change the {modalEdit.type === 1 ? "username" : "email"} and
                  then click on Save.
                </p>
                <input
                  type="text"
                  onChange={inputChangeHandler}
                  value={inputValue}
                  placeholder={`Enter ${
                    modalEdit.type === 1 ? "username" : "email"
                  }`}
                />
                <span className="my-profile-edit-modal__error">{error}</span>
              </div>
              <div className="my-profile-edit-modal__actions">
                <button
                  className="my-profile-edit-modal__actions--cancel"
                  onClick={closeEditModalHandler}
                >
                  Cancel
                </button>
                {isLoading && <Spinner />}
                {!isLoading && (
                  <button
                    className="my-profile-edit-modal__actions--save"
                    onClick={editUsernameMailHandler}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </ModalProfile>
        )}
        <div className="my-profile__user-data">
          <div className="my-profile__user-data--container">
            <p>Full name</p>
            <div>
              <span>{userName ? userName : "Username"}</span>
              <button onClick={editUsernameHandler}>Edit</button>
            </div>
          </div>
          <div className="my-profile__user-data--container">
            <p>E-mail address</p>
            <div>
              <span>{userEmail}</span>
              <button onClick={editEmailHandler}>Edit</button>
            </div>
          </div>
        </div>
      </div>
    </ProfileContainer>
  );
};

export default ProfilePage;
