import { Fragment, useCallback, useEffect, useState } from "react";
import "../assets/styles/components/_magic-login-user.scss";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { initialize_user } from "../store/actions/authActions";
import useHttp from "../hooks/use-http";
import Spinner from "../UI/Spinner";
import { saveAs } from "file-saver";
import ButtonBlack from "../UI/ButtonBlack";

const MagicLoginUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const { isLoading, error, sendRequest: sendMagicLoginRequest } = useHttp();

  const {
    error: errorSaveToCollection,
    sendRequest: sendSaveToCollectionRequest,
  } = useHttp();
  const { error: errorSubscribe, sendRequest: sendSubscribeRequest } =
    useHttp();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const temp_img = new URLSearchParams(search).get("temp_img");
  const expires = new URLSearchParams(search).get("expires");
  const signature = new URLSearchParams(search).get("signature");
  const redirect_to = new URLSearchParams(search).get("redirect_to");
  const type = parseInt(new URLSearchParams(search).get("type"));

  const handleSubscribeResponse = useCallback(
    (response) => {
      history.replace(redirect_to);
      setIsLoadingSave(false);
      window.location.href = response.checkout_url;
    },
    [history, redirect_to]
  );
  const handeSaveToCollectionResponse = useCallback(() => {
    history.replace(redirect_to);
    setIsLoadingSave(false);
  }, [redirect_to, history]);

  const handleMagicLoginResponse = useCallback(
    (response) => {
      dispatch(initialize_user(response.user, response.token));
      if (type === 2) {
        setIsLoadingSave(true);
        const formData = new FormData();
        fetch(temp_img).then(async (response_img) => {
          const contentType = response_img.headers.get("content-type");
          const blob = await response_img.blob();
          const file = new File([blob], "image.jpg", { contentType });
          formData.append("image", file);
          sendSaveToCollectionRequest(
            {
              url: "/api/image/save-collection",
              method: "POST",
              body: formData,
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${response.token}`,
              },
            },
            handeSaveToCollectionResponse
          );
        });
      } else if (type === 1) {
        history.replace(redirect_to);
      } else if (type === 3) {
        setIsLoadingSave(true);
        sendSubscribeRequest(
          {
            url: "/api/user/subscribe/checkout",
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${response.token}`,
            },
          },
          handleSubscribeResponse
        );
      } else {
        history.replace("/");
      }
    },
    [
      history,
      redirect_to,
      dispatch,
      temp_img,
      type,
      sendSaveToCollectionRequest,
      handeSaveToCollectionResponse,
      sendSubscribeRequest,
      handleSubscribeResponse,
    ]
  );

  useEffect(() => {
    if (errorSubscribe || errorSaveToCollection) {
      setIsLoadingSave(false);
    }
  }, [errorSubscribe, errorSaveToCollection]);

  useEffect(() => {
    if (temp_img && type === 1) {
      saveAs(temp_img, "pharseit-image.jpg");
    }

    let redirectQuery = "%2F" + redirect_to.slice(1, redirect_to.length);
    sendMagicLoginRequest(
      {
        url: `/magic-link/${id}?expires=${expires}&redirect_to=${redirectQuery}&signature=${signature}`,
        method: "POST",
        headers: {
          Accept: "applicaton/json",
        },
      },
      handleMagicLoginResponse
    );
  }, [
    expires,
    redirect_to,
    signature,
    id,
    temp_img,
    type,
    sendMagicLoginRequest,
    handleMagicLoginResponse,
  ]);

  return (
    <Fragment>
      {(isLoading || isLoadingSave) && (
        <div className="loading">
          <Spinner spinnerStyle="lds-ripple" />
        </div>
      )}
      <div className="magic-login__error">
        {(error || errorSubscribe || errorSaveToCollection) && (
          <h1>
            {error
              ? error
              : errorSubscribe
              ? errorSubscribe
              : errorSaveToCollection}
          </h1>
        )}
        <ButtonBlack onClick={() => history.push("/")}>Go Back</ButtonBlack>
      </div>
    </Fragment>
  );
};

export default MagicLoginUser;
