import useHttp from "../hooks/use-http";
import useInput from "../hooks/use-input";
import { saveAs } from "file-saver";
import { useSelector, useDispatch } from "react-redux";
import { reset_rnd_state } from "../store/actions/rndContainerActions";
//styles
import "../assets/styles/UI/_modal-save-share-down.scss";

//icons
import { ReactComponent as CloseGrey } from "../assets/images/icons/cancel.svg";
import { ReactComponent as Spinner } from "../assets/images/icons/spinner.svg";

const ModalSaveShareDown = (props) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const userToken = useSelector((state) => state.authReducer.userToken);
  const { isLoading: isLoadingSave, sendRequest: sendSaveToCollectionRequest } =
    useHttp();
  const {
    isLoading: isLoadingDownload,
    sendRequest: sendDownloadImageRequest,
  } = useHttp();
  const { isLoading: isLoadingShare, sendRequest: sendShareImageRequest } =
    useHttp();
  const { isLoading: isLoadingCheckEmail, sendRequest: sendCheckEmailRequest } =
    useHttp();
  const {
    value: emailValue,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset,
  } = useInput((value) =>
    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)
  );
  let modalStyles = "modal";
  if (props.type === "save") modalStyles = "modal modal-save";
  if (props.type === "download") modalStyles = "modal modal-download";

  const closeModalHandler = () => {
    props.closeModal();
    reset();
  };

  const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;

    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {
      type: mimeString,
    });
  };

  const handleRequestResponse = () => {
    dispatch(reset_rnd_state());
    props.closeModal();
    reset();
    props.onSuccess("Email with login details has been sent to you.");
  };

  const handleRequestShareResponse = () => {
    dispatch(reset_rnd_state());
    props.closeModal();
    reset();
    props.onSuccess("Email sent successfully.");
  };

  const handleCheckEmailResponse = (response) => {
    const formData = new FormData();
    formData.append("email", emailValue);
    formData.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
    const blob = dataURItoBlob(props.image);
    formData.append("image", blob, "pharseit-image.jpg");
    formData.append("type", 1);
    sendDownloadImageRequest(
      {
        url: response.exists ? "/api/login-api" : "/api/register",
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      },
      handleRequestResponse
    );
  };

  const handleCheckEmailResponseCollection = (response) => {
    const formData = new FormData();
    formData.append("email", emailValue);
    formData.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
    const blob = dataURItoBlob(props.image);
    formData.append("image", blob, "pharseit-image.jpg");
    formData.append("type", 2);
    sendSaveToCollectionRequest(
      {
        url: response.exists ? "/api/login-api" : "/api/register",
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      },
      handleRequestResponse
    );
  };

  const shareImageHandler = () => {
    const formData = new FormData();
    formData.append("email_to", emailValue);
    const blob = dataURItoBlob(props.image);
    formData.append("image", blob, "pharseit-image.jpg");
    sendShareImageRequest(
      {
        url: "/api/image/share",
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      },
      handleRequestShareResponse
    );
  };

  const downloadImageHandler = () => {
    if (isLoggedIn) {
      saveAs(props.image, "pharseit-Image.jpg");
      dispatch(reset_rnd_state());
      props.closeModal();
      props.onSuccess("The image is downloading...");
    } else {
      const formData = new FormData();
      formData.append("email", emailValue);
      sendCheckEmailRequest(
        {
          url: "/api/check-email",
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        },
        handleCheckEmailResponse
      );
    }
  };
  const handleSaveToCollectionResponse = () => {
    dispatch(reset_rnd_state());
    props.closeModal();
    reset();
    props.onSuccess("Image saved to my collection.");
  };
  const saveToCollectionHandler = () => {
    if (isLoggedIn) {
      const formData = new FormData();
      const blob = dataURItoBlob(props.image);
      formData.append("image", blob, "phareseit-image.jpg");
      sendSaveToCollectionRequest(
        {
          url: "/api/image/save-collection",
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        },
        handleSaveToCollectionResponse
      );
    } else {
      const formData = new FormData();
      formData.append("email", emailValue);
      sendCheckEmailRequest(
        {
          url: "/api/check-email",
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        },
        handleCheckEmailResponseCollection
      );
    }
  };

  return (
    <div className={modalStyles}>
      {props.type === "save" && (
        <div className="modal-save__header">
          <h2>Save to collection</h2>
          <button onClick={closeModalHandler}>
            <CloseGrey />
          </button>
        </div>
      )}
      <div className="modal__description">
        <div className="modal__description-image">
          <img
            src={props.image}
            className="image-screenshot"
            alt="Screenshot"
          />
        </div>
        {props.type === "save" && !isLoggedIn && (
          <div className="modal__description-content">
            <div className="modal__description-content--text">
              <p>
                In order to add your image in your personal collection, we
                should create you an account.
              </p>
              <p>
                Please enter your e-mail so we can send you a magic link to log
                in.
              </p>
            </div>
            <input
              type="email"
              required
              placeholder="E-Mail Address"
              onChange={emailChangeHandler}
              onBlur={emailBlurHandler}
              value={emailValue}
            />
            {emailHasError && (
              <p className="modal__description-error">
                Please enter valid email.
              </p>
            )}
            <div className="modal__actions">
              <button className="cancel" onClick={props.closeModal}>
                Cancel
              </button>
              {(isLoadingSave || isLoadingCheckEmail) && <Spinner />}
              {!isLoadingSave && !isLoadingCheckEmail && (
                <button
                  className={
                    emailHasError
                      ? "modal__actions-btn modal__actions-btn-not-valid"
                      : "modal__actions-btn"
                  }
                  onClick={saveToCollectionHandler}
                  disabled={emailHasError}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        )}
        {props.type === "save" && isLoggedIn && (
          <div className="modal__description-content">
            <div className="modal__description-content--text">
              <p>By proceeding you agree to our Terms Of Service.</p>
              <p>
                You confirm that no copyrights are violated and this photo is of
                defamatory or libelous nature.
              </p>
            </div>
            <div className="modal__actions">
              {isLoadingSave && <Spinner />}
              {!isLoadingSave && (
                <button
                  className="modal__actions-btn"
                  onClick={saveToCollectionHandler}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        )}

        {props.type === "download" && !isLoggedIn && (
          <div className="modal__description-content">
            <h3 className="modal__description-content--title">
              Download your image
            </h3>
            <div className="modal__description-content--text">
              <p>
                In order to download your image, we should create you an
                account.
              </p>
              <p>
                Please enter your e-mail so we can send you a magic link to
                download.
              </p>
            </div>
            <input
              type="email"
              required
              placeholder="E-Mail Address"
              onChange={emailChangeHandler}
              onBlur={emailBlurHandler}
              value={emailValue}
            />
            {emailHasError && (
              <p className="modal__description-error">
                Please enter valid email.
              </p>
            )}
            <div className="modal__actions">
              <button className="cancel" onClick={props.closeModal}>
                Cancel
              </button>
              {(isLoadingDownload || isLoadingCheckEmail) && <Spinner />}
              {!isLoadingDownload && !isLoadingCheckEmail && (
                <button
                  className={
                    emailHasError
                      ? "modal__actions-btn modal__actions-btn-not-valid"
                      : "modal__actions-btn"
                  }
                  onClick={downloadImageHandler}
                  disabled={emailHasError}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        )}
        {props.type === "download" && isLoggedIn && (
          <div className="modal__description-content">
            <h3 className="modal__description-content--title">
              Download your image
            </h3>
            <div className="modal__description-content--text">
              <p>By proceeding you agree to our Terms Of Service.</p>
              <p>
                You confirm that no copyrights are violated and this photo is of
                defamatory or libelous nature.
              </p>
            </div>
            <div className="modal__actions">
              <button className="cancel" onClick={props.closeModal}>
                Cancel
              </button>
              <button
                className="modal__actions-btn"
                onClick={downloadImageHandler}
              >
                Alright Download
              </button>
            </div>
          </div>
        )}
        {props.type === "share" && (
          <div className="modal__description-content">
            <h3 className="modal__description-content--title">
              Share your image
            </h3>
            <div className="modal__description-content--text">
              <p>
                Share this photo to a friend by entering their email address
                below.
              </p>
            </div>
            <br />
            <input
              type="email"
              required
              placeholder="E-Mail Address"
              onChange={emailChangeHandler}
              onBlur={emailBlurHandler}
              value={emailValue}
            />
            {emailHasError && (
              <p className="modal__description-error">
                Please enter valid email.
              </p>
            )}
            <br />
            <div className="modal__actions">
              <button className="cancel" onClick={props.closeModal}>
                Cancel
              </button>
              {isLoadingShare && <Spinner />}
              {!isLoadingShare && (
                <button
                  className={
                    emailHasError
                      ? "modal__actions-btn modal__actions-btn-not-valid"
                      : "modal__actions-btn"
                  }
                  disabled={emailHasError}
                  onClick={shareImageHandler}
                >
                  Send
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalSaveShareDown;
