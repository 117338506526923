import "../assets/styles/containers/_profile-container.scss";
import MiddleTopHeader from "../components/MiddleTopHeader";
import imageLogo from "../assets/images/logos/logoPhraseit.png";
import MobileMenuUser from "../UI/MobileMenuUser";
import Media from "react-media";

const ProfileContainer = (props) => {
  return (
    <div className="profile-container">
      <Media queries={{ small: "(max-width: 900px)" }}>
        {(matches) =>
          matches.small ? (
            <MiddleTopHeader/>
          ) : (
            <div className="profile-container__sidebar">
              <div className="profile-container__sidebar--img">
                <img src={imageLogo} alt="logo" />
              </div>
              <MobileMenuUser />
            </div>
          )
        }
      </Media>

      <div className="profile-container__content">{props.children}</div>
    </div>
  );
};

export default ProfileContainer;
