import React, { useState, Fragment, useEffect, useRef } from "react";
import Media from "react-media";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

//styles
import "../assets/styles/components/_middle-top-header.scss";

//icons

import { ReactComponent as IconCollections } from "../assets/images/icons/iconSave.svg";
import { ReactComponent as IconMyProfile } from "../assets/images/icons/iconMyProfile.svg";
//components
import Logo from "../UI/Logo";
import ModalCreateLogin from "../UI/ModalCreateLogin";
import Auxilary from "../hoc/Auxilary";
import Backdrop from "../UI/Backdrop";
import Message from "../UI/Message";
import MobileMenu from "../UI/MobileMenu";
import MobileMenuUser from "../UI/MobileMenuUser";

const MiddleTopHeader = () => {
  const timeoutRef = useRef(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [messageText, setMessageText] = useState("");
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const history = useHistory();

  const mobileMenuHandler = () => {
    setIsMenuOpened((prev) => !prev);
  };

  const [openModal, setOpenModal] = useState({
    open: false,
    type: null,
  });

  const openModalHandler = (type) => {
    setOpenModal({
      open: true,
      type,
    });
  };
  const closeModalHandler = () => {
    setOpenModal({
      open: false,
      type: null,
    });
  };

  const closeMessageHandler = () => {
    setSuccessMessage(false);
  };

  const handleCreateLoginResponse = (response) => {
    setOpenModal({
      open: false,
      type: null,
    });
    setMessageText(response.message);
    setSuccessMessage(true);
  };

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    resetTimeout();
    setTimeout(() => {
      setSuccessMessage(false);
    }, 3000);
    return () => {
      resetTimeout();
    };
  }, [successMessage]);

  return (
    <div className="middle-top-header__container">
      <div className="middle-top-header">
        <Logo styles="logo" />
        <div className="header-profiles-collections">
          <Media queries={{ small: "(max-width: 900px)" }}>
            {(matches) =>
              matches.small ? (
                <MobileMenu onClick={mobileMenuHandler} />
              ) : (
                <Fragment>
                  {!isLoggedIn && (
                    <Fragment>
                      <button
                        className="my-profile-btn"
                        onClick={() => openModalHandler("create")}
                      >
                        <IconMyProfile />
                        <span> Create Account</span>
                      </button>
                      <button
                        className="my-collection-btn"
                        onClick={() => openModalHandler("login")}
                      >
                        <span>Login</span>
                      </button>
                    </Fragment>
                  )}
                  {isLoggedIn && (
                    <Fragment>
                      <button
                        className="btn-user"
                        onClick={() => history.push("/profile")}
                      >
                        <IconMyProfile />
                        <span>My Profile</span>
                      </button>
                      <button
                        className="btn-user"
                        onClick={() => history.push("/collections")}
                      >
                        <IconCollections />
                        <span>My Collection</span>
                      </button>
                    </Fragment>
                  )}
                </Fragment>
              )
            }
          </Media>
        </div>
        {openModal.open && (
          <Auxilary>
            <Backdrop show={true} onClose={closeModalHandler}>
              <ModalCreateLogin
                type={openModal.type}
                closeModal={() => closeModalHandler()}
                handleCreateLoginResponse={handleCreateLoginResponse}
              />
            </Backdrop>
          </Auxilary>
        )}
      </div>
      {isMenuOpened && !isLoggedIn && (
        <div className="mobile-menu__content">
          <button
            onClick={() => {
              setIsMenuOpened(false);
              openModalHandler("create");
            }}
          >
            <IconMyProfile />
            <span> Create Account</span>
          </button>
          <button
            onClick={() => {
              setIsMenuOpened(false);
              openModalHandler("login");
            }}
          >
            <span>Login</span>
          </button>
        </div>
      )}
      {isMenuOpened && isLoggedIn && (
        <MobileMenuUser onClose={setIsMenuOpened} />
      )}
      {successMessage && (
        <Message text={messageText} onClose={closeMessageHandler} />
      )}
    </div>
  );
};
export default MiddleTopHeader;
