import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";

//styles
import "../assets/styles/containers/_rnd-container.scss";

import {
  set_active_rnd_container,
  add_new_rnd_container,
  remove_rnd_container,
} from "../store/actions/rndContainerActions";

//react own components imports
import Auxilary from "../hoc/Auxilary";
import RndBubble from "../components/RndBubble";

//images
import trash from "../assets/images/icons/trash.svg";
import NewRndBubbleContainer from "../components/NewRndBubbleContainer";

const RndBubblesContainer = (props) => {
  const containerRef = useRef(null);
  const emptyContainerRef = useRef(null);
  const [containerWrapperHover, setContainerWrapperHover] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const dispatch = useDispatch();
  const setDraggingCallback = useCallback((value) => {
    setIsDragging(value);
  }, []);
  const rndBubbles = props.data.activeBubbles.map((item, index) => {
    return (
      <RndBubble
        bubblePath={item.variations.right}
        variations={item.variations}
        offsets={item.offsets}
        containerId={props.data.id}
        id={item.id}
        key={item.id}
        isLoading={props.isLoading}
        isDragging={setDraggingCallback}
      />
    );
  });

  const containerClicked = () => {
    setContainerWrapperHover(true);
    dispatch(set_active_rnd_container(props.id));
  };
  useEffect(() => {
    if (props.active) {
      if (props.data.activeImage && containerRef.current) {
        containerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        // } else if (!props.data.activeImage && emptyContainerRef.current) {
        //   emptyContainerRef.current.scrollIntoView({
        //     behavior: "smooth",
        //     block: "start",
        //   });
      }
    }
  }, [props.active, props.data.activeImage]);

  return props.data.activeImage ? (
    <div
      ref={containerRef}
      className={
        props.active
          ? "active-container rnd-container-wrapper"
          : "rnd-container-wrapper"
      }
      style={{ height: props.data.height + "px" }}
      onMouseEnter={() => setContainerWrapperHover(true)}
      onMouseLeave={() => setContainerWrapperHover(false)}
      onClick={() => containerClicked()}
    >
      <div
        className="rnd-container"
        style={{ backgroundImage: props.data.activeImage }}
      >
        <div className="screenshot">{rndBubbles}</div>
      </div>

      {containerWrapperHover && !isDragging && !props.isLoading ? (
        <Auxilary>
          {props.last && (
            <button
              className="add-container-button"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(add_new_rnd_container());
              }}
            >
              Add additional photo
            </button>
          )}
          <button
            className="remove-container-button"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(remove_rnd_container(props.id));
            }}
          >
            <img src={trash} alt="trash" />
          </button>
        </Auxilary>
      ) : null}
    </div>
  ) : (
    !props.clickTriggered && (
      <NewRndBubbleContainer
        refToComponent={emptyContainerRef}
        onTouchStartProp={() => containerClicked()}
        onClickProp={() => containerClicked()}
        active={props.active}
      />
    )
  );
};

export default RndBubblesContainer;
