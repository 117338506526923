import * as actionTypes from "./actionTypes";

export const initialize_bubble_component = (activeImage,variations, containerId, bubbleId, font, color, opacity,opacityBubble) => {
    return {
        type: actionTypes.INITIALIZE_BUBBLE_COMPONENT,
        activeImage,
        variations,
        containerId,
        bubbleId,
        fontStyle: font,
        color,
        opacity,
        opacityBubble
    }
}
export const set_active_font = (font,containerId,bubbleId) => {
    return {
        type: actionTypes.SET_ACTIVE_FONT,
        fontStyle: font,
        containerId,
        bubbleId
    }
}

export const set_active_color = (color, containerId,bubbleId) => {
    return {
        type: actionTypes.SET_ACTIVE_COLOR,
        color,
        containerId,
        bubbleId
    }
}

export const set_active_bubble= (containerId, bubbleId) => {
    return {
        type: actionTypes.SET_ACTIVE_BUBBLE,
        containerId,
        bubbleId
    }
}


export const set_active_opacity = (opacity,containerId,bubbleId) => {
    return {
        type: actionTypes.SET_ACTIVE_OPACITY,
        opacity: opacity,
        containerId,
        bubbleId
    }
}
export const set_active_opacity_bubble = (opacity,containerId,bubbleId) => {
    return {
        type: actionTypes.SET_ACTIVE_OPACITY_BUBBLE,
        opacity: opacity,
        containerId,
        bubbleId
    }
}


export const set_active_degree = (degree,containerId,bubbleId) => {
    return {
        type: actionTypes.SET_ROTATION_DEGREE,
        degree,
        containerId,
        bubbleId
    }
}


