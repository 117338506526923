import * as actionTypes from "../actions/actionTypes";

const initialState = {
  activeRndContainerId: 1,
  containers: [
    {
      id: 1,
      activeImage: null,
      height: null,
      activeBubbles: [],
    },
  ],
  newBubbleIdGenerate: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_RND_STATE:
      return {
        activeRndContainerId: 1,
        containers: [
          {
            id: 1,
            activeImage: null,
            height: null,
            activeBubbles: [],
          },
        ],
        newBubbleIdGenerate: 0,
      };
    case actionTypes.ADD_NEW_RND_CONTAINER:
      let containersCopySlice = state.containers.slice();
      containersCopySlice.push({
        id: state.containers.length + 1,
        activeImage: null,
        height: null,
        activeBubbles: [],
      });
      return {
        ...state,
        containers: containersCopySlice,
        activeRndContainerId: state.activeRndContainerId + 1,
      };
    case actionTypes.REMOVE_RND_CONTAINER:
      let containersCopyDeletion = [];
      let counted = 1;
      for (let item in state.containers) {
        if (state.containers[item].id !== action.id) {
          containersCopyDeletion.push({
            ...state.containers[item],
            id: counted++,
          });
        }
      }
      return {
        ...state,
        containers:
          containersCopyDeletion.length !== 0
            ? containersCopyDeletion
            : [
                {
                  id: 1,
                  activeImage: null,
                  height: null,
                  activeBubbles: [],
                },
              ],
        activeRndContainerId: counted === 1 ? counted : counted - 1,
      };
    case actionTypes.SET_ACTIVE_RND_CONTAINER:
      return {
        ...state,
        activeRndContainerId: action.id,
      };
    case actionTypes.SET_BUBBLE:
      let containersCopy = [];
      for (let item in state.containers) {
        if (state.activeRndContainerId === state.containers[item].id) {
          if (state.containers[item].activeImage === null) return { ...state };
          let bubblesCopy = state.containers[item].activeBubbles.slice();
          bubblesCopy.push({
            ...action.bubbleData,
            id: state.newBubbleIdGenerate,
          });
          containersCopy.push({
            ...state.containers[item],
            activeBubbles: bubblesCopy,
          });
        } else {
          containersCopy.push({ ...state.containers[item] });
        }
      }
      // bubblesCopy.push({ ...action.imageData, id: bubblesCopy.length });
      return {
        ...state,
        containers: containersCopy,
        newBubbleIdGenerate: state.newBubbleIdGenerate + 1,
      };
    case actionTypes.CLOSE_BUBBLE:
      let containersCopy2 = [];
      for (let item in state.containers) {
        if (state.activeRndContainerId === state.containers[item].id) {
          let bubblesCopy2 = [];
          // let newIdCountGenerator = 0;
          for (let item2 in state.containers[item].activeBubbles) {
            if (state.containers[item].activeBubbles[item2].id !== +action.id) {
              bubblesCopy2.push({
                ...state.containers[item].activeBubbles[item2],
                // id: newIdCountGenerator
              });
              // newIdCountGenerator++;
            }
          }
          containersCopy2.push({
            ...state.containers[item],
            activeBubbles: bubblesCopy2,
          });
        } else {
          containersCopy2.push({ ...state.containers[item] });
        }
      }
      // bubblesCopy.push({ ...action.imageData, id: bubblesCopy.length });
      return {
        ...state,
        containers: containersCopy2,
      };

    case actionTypes.SET_BACKGROUND_IMAGE:
      let rndContainersCopy = [];
      for (let item in state.containers) {
        if (state.containers[item].id === +state.activeRndContainerId) {
          rndContainersCopy.push({
            ...state.containers[item],
            activeImage: action.activeImage,
            height: action.height,
          });
        } else {
          rndContainersCopy.push({ ...state.containers[item] });
        }
      }
      return {
        ...state,
        containers: rndContainersCopy,
      };

    default:
      return state;
  }
};

export default reducer;
