import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";

import "../assets/styles/components/_rnd-component.scss";
import Auxilary from "../hoc/Auxilary";

import { close_bubble } from "../store/actions/rndContainerActions";
import {
  initialize_bubble_component,
  set_active_bubble,
} from "../store/actions/stylesActions";
import { set_active_degree } from "../store/actions/stylesActions";

const RndBubble = (props) => {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(180);
  const [height, setHeigth] = useState(180);
  const [x, setX] = useState(10);
  const [y, setY] = useState(10);
  const [textValue, setTextValue] = useState("");
  const [mouseEntered, setMouseEntered] = useState(false);
  const textAreaRef = useRef(null);
  const rndRef = useRef(null);
  const {
    //text_offset_y_up,
    textarea_offset_x,
    textarea_offset_y,
    textarea_size_x,
    textarea_size_y,
  } = props.offsets;

  const compnentStyles = useSelector((state) => {
    if (
      state.stylesReducer.allBubbles[props.containerId] === null ||
      state.stylesReducer.activeBubbleId === null
    )
      return null;
    return state.stylesReducer.allBubbles[props.containerId].find(
      (item) => item.bubbleId === props.id
    );
  });

  const onKeyUp = () => {
    let widthContainer = textAreaRef.current.style.width.slice(
      0,
      textAreaRef.current.style.width.length - 2
    );
    let heightContainer = textAreaRef.current.style.height.slice(
      0,
      textAreaRef.current.style.height.length - 2
    );
    adjustFontSize(widthContainer - 10, heightContainer - 10);
  };

  useEffect(() => {
    let widthTextArea = (width / 100) * textarea_size_x;
    let heightTextArea = (height / 100) * textarea_size_y;
    textAreaRef.current.style.width = `${(width / 100) * textarea_size_x}px`;
    textAreaRef.current.style.height = `${(height / 100) * textarea_size_y}px`;
    textAreaRef.current.style.marginLeft = `${
      (width / 100) * textarea_offset_x
    }px`;
    textAreaRef.current.style.marginTop = `${
      (height / 100) * textarea_offset_y
    }px`;
    adjustFontSize(widthTextArea - 10, heightTextArea - 10);
  }, []);

  useEffect(() => {
    dispatch(
      initialize_bubble_component(
        props.bubblePath,
        props.variations,
        props.containerId,
        props.id,
        { value: "Arial", label: "Arial" },
        "#000000",
        [100],
        [100]
      )
    );
    return () => {
      console.log("unmount");
    };
  }, []);

  useEffect(() => {
    if (props.isLoading) {
      setMouseEntered(false);
    }
  }, [props.isLoading]);

  const onResize = (e, direction, ref, delta, position) => {
    setWidth(ref.style.width);
    setHeigth(ref.style.height);
    setX(position.x);
    setY(position.y);

    props.isDragging(false);
    if (!textAreaRef.current) return;

    let widthContainer = ref.style.width.slice(0, ref.style.width.length - 2);
    let heightContainer = ref.style.height.slice(
      0,
      ref.style.height.length - 2
    );

    let widthTextArea = (widthContainer / 100) * textarea_size_x;
    let heightTextArea = (heightContainer / 100) * textarea_size_y;
    textAreaRef.current.style.width = `${
      (widthContainer / 100) * textarea_size_x
    }px`;
    textAreaRef.current.style.height = `${
      (heightContainer / 100) * textarea_size_y
    }px`;
    textAreaRef.current.style.marginLeft = `${
      (widthContainer / 100) * textarea_offset_x
    }px`;
    textAreaRef.current.style.marginTop = `${
      (heightContainer / 100) * textarea_offset_y
    }px`;
    adjustFontSize(widthTextArea - 10, heightTextArea - 10);
  };

  const onResizeStop = (e, direction, ref, delta, position) => {
    setWidth(ref.style.width);
    setHeigth(ref.style.height);
    setX(position.x);
    setY(position.y);
    props.isDragging(false);
  };

  const onDragStop = (e, d) => {
    setX(d.x);
    setY(d.y);
    props.isDragging(false);
  };

  const closeRnd = () => {
    dispatch(close_bubble(+props.id, props.containerId));
  };
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []);

  const rotateDegree =
    compnentStyles && compnentStyles.degree ? compnentStyles.degree : 0;

  let fontStyles = {
    fontSize: "40px",
    fontFamily:
      compnentStyles && compnentStyles.fontStyle
        ? compnentStyles.fontStyle.value
        : "Arial",
    color:
      compnentStyles && compnentStyles.color ? compnentStyles.color : "black",
    opacity:
      compnentStyles && compnentStyles.opacity
        ? compnentStyles.opacity[0] / 100
        : "1",
    transform: `rotate(-${rotateDegree}deg)`,
  };

  const adjustFontSize = (width, height) => {
    let fontSize = 60;
    let fontFamily = fontStyles.fontFamily;
    let longestWord = "";
    const textWords = textValue.trim().split(/( |\n)/);

    textWords.forEach((item) => {
      if (item.length > longestWord.length) {
        longestWord = item;
      }
    });

    do {
      fontSize = fontSize - 1;
      textAreaRef.current.style.fontSize = `${fontSize}px`;
    } while (
      getTextMetrics(longestWord, { size: fontSize, family: fontFamily })
        .width > width
    );

    const calculateTextHeight = () => {
      let heightText = 0;

      const textLines = convertTextIntoLines(width, {
        size: fontSize,
        family: fontFamily,
      });

      textLines.forEach((item) => {
        heightText +=
          getTextMetrics(item, { size: fontSize, family: fontFamily }).height *
          1.25;
      });
      return heightText;
    };

    let heightText = calculateTextHeight();
    let heightTextCopy = 0;

    do {
      heightTextCopy = heightText;
      fontSize = fontSize - 1;
      textAreaRef.current.style.fontSize = `${fontSize}px`;
      heightText = calculateTextHeight();
    } while (heightText > height && fontSize > 3);

    if (textValue) {
      let offsetY = ((height - heightTextCopy) / 5) * 2;
      textAreaRef.current.style.paddingTop = `${offsetY}px`;
    }
  };

  const convertTextIntoLines = (widthContainer, font) => {
    const text = textValue.trim().split(/( |\n)/);
    let currentLine = "";
    let currentWidth = 0;
    const lines = [];
    text.forEach((item) => {
      if (item !== "\n") {
        let textMetrics = getTextMetrics(item, font);
        if (textMetrics.width + currentWidth < widthContainer) {
          currentLine += item;
          currentWidth += textMetrics.width;
        } else if (textMetrics.width > widthContainer) {
          if (currentLine) {
            lines.push(currentLine);
            lines.push(item);
            currentLine = "";
            currentWidth = 0;
          } else {
            lines.push(item);
          }
        } else {
          lines.push(currentLine);
          currentLine = item;
          currentWidth = textMetrics.width;
        }
      } else {
        if (currentLine) {
          lines.push(currentLine);
        }
        currentLine = "";
        currentWidth = 0;
        lines.push("\n");
      }
    });
    if (currentLine) {
      lines.push(currentLine);
    }
    return lines;
  };

  const getTextMetrics = (text, font) => {
    // re-use canvas object for better performance
    const canvas =
      getTextMetrics.canvas ||
      (getTextMetrics.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = `${font.size}px ${font.family}`;
    const metrics = context.measureText(text);
    const height =
      metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
    return {
      height: height,
      width: metrics.width,
    };
  };

  const handleBubbleDegree = (event) => {
    event?.stopPropagation();
    event?.preventDefault();
    dispatch(set_active_degree(60, props.containerId, props.id));
  };

  return (
    <Rnd
      className="rnd-custom-class"
      resizeHandleClasses={
        mouseEntered ? { bottomRight: "bottom-right-custom" } : ""
      }
      dragHandleClassName={"rnd-handle"}
      size={{ width: width, height: height }}
      position={{ x: x, y: y }}
      minWidth={80}
      minHeight={80}
      on={"true"}
      bounds={"parent"}
      onDragStop={(e, d) => onDragStop(e, d)}
      onResizeStop={(e, direction, ref, delta, position) =>
        onResizeStop(e, direction, ref, delta, position)
      }
      onResize={(e, direction, ref, delta, position) =>
        onResize(e, direction, ref, delta, position)
      }
      onMouseEnter={() => setMouseEntered(true)}
      onMouseLeave={() => setMouseEntered(false)}
      onTouchStart={() => {
        setMouseEntered(true);
        textAreaRef.current.focus();
        textAreaRef.current.click();
      }}
      onTouchEnd={() => setMouseEntered(false)}
      enableUserSelectHack={false}
      ref={rndRef}
      onDragStart={() => props.isDragging(true)}
    >
      <div
        // style={{ backgroundImage: "url(/filter-images/" + props.imageType + "/" + props.imageVariations[variationNumber] + ")" }}
        style={{
          backgroundImage: `url(${
            compnentStyles ? compnentStyles.activeImage : props.bubblePath
          })`,
          transform: `rotate(${rotateDegree}deg)`,
          opacity:
            compnentStyles && compnentStyles.opacityBubble
              ? compnentStyles.opacityBubble[0] / 100
              : "1",
        }}
        className="bubble-image"
      >
        <div
          ref={textAreaRef}
          contentEditable="true"
          className="rnd-textarea"
          onInput={(event) => setTextValue(event.target.innerText)}
          onKeyUp={(e) => onKeyUp(e)}
          onClick={() => {
            dispatch(set_active_bubble(props.containerId, props.id));
          }}
          style={fontStyles}
        />
      </div>

      {mouseEntered ? (
        <Auxilary>
          <div
            className="side-option-close"
            onClick={() => closeRnd()}
            onTouchStart={() => closeRnd()}
          />
          <div className="rnd-handle">
            <div className="rnd-handle__icon"></div>
            <div className="rnd-handle__left"></div>
            <div className="rnd-handle__right"></div>
            <div className="rnd-handle__top"></div>
            <div className="rnd-handle__bottom"></div>
          </div>
          <div className="side-option-rotate" onClick={handleBubbleDegree} />
        </Auxilary>
      ) : null}
    </Rnd>
  );
};

export default React.memo(RndBubble);
