import React, { useState } from "react";
import { useSelector } from "react-redux";
//styles
import "../assets/styles/UI/_modal-payment-bubbles.scss";

//icons
import closeGrey from "../assets/images/icons/close_grey.svg";
import tickIcon from "../assets/images/icons/tick.svg";
import { ReactComponent as Spinner } from "../assets/images/icons/spinner.svg";
import imageDefault from "../assets/images/icons/medium-left.png";

import ModalProfile from "./ModalProfile";
import useHttp from "../hooks/use-http";

const ModalPaymentProBubbles = (props) => {
  const [paymentOption, setPaymentOption] = useState(1);
  const userToken = useSelector((state) => state.authReducer.userToken);
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const { isLoading, error, sendRequest: sendSubscribeRequest } = useHttp();
  let imagePath = props.bubbleImagePath ? props.bubbleImagePath : imageDefault;

  const handleSubscribeResponse = (response) => {
    window.location.href = response.checkout_url;
  };
  const becomeProHandler = () => {
    if (isLoggedIn) {
      const formData = new FormData();

      formData.append("annually", paymentOption);
      sendSubscribeRequest(
        {
          url: "/api/user/subscribe/checkout",
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        },
        handleSubscribeResponse
      );
    } else {
      props.openCreateLogin();
    }
  };

  return (
    <ModalProfile onClose={props.onClose}>
      <div className="modal-payment-bubbles">
        <div className="header">
          Upgrade to PRO
          <button onClick={props.onClose}>
            <img src={closeGrey} alt="close-icon" />
          </button>
        </div>

        <div className="description">
          <div className="description-left">
            <div className="payment-options-buttons">
              <button
                className={paymentOption === 1 ? "active-button" : ""}
                onClick={() => setPaymentOption(1)}
              >
                Annual
              </button>
              <button
                className={paymentOption === 0 ? "active-button" : ""}
                onClick={() => setPaymentOption(0)}
              >
                Lifetime
              </button>
            </div>

            <div className="chosen-option-description">
              <span className="price">
                {paymentOption === 1 && "$4.99"}
                {paymentOption === 0 && "$14.99"}
              </span>
              <span className="price-description">
                {paymentOption === 1 &&
                  "Upgrade to Phraseit PRO and enjoy more type of bubbles, effects, rotations, font colors, etc. for a period of one year."}
                {paymentOption === 0 &&
                  "Pay once and get a lifetime access to Phraseit PRO to use more type of bubbles, effects, rotations, font colors etc."}
              </span>
            </div>

            <div className="chosen-option-types">
              <div>
                <img src={tickIcon} alt="tick-mark" /> Explore custom festive
                bubbles
              </div>
              <div>
                <img src={tickIcon} alt="tick-mark" /> Generate up to 10 AI
                images per month
              </div>
              <div>
                <img src={tickIcon} alt="tick-mark" /> Browse freely with zero
                ads
              </div>
              <div>
                <img src={tickIcon} alt="tick-mark" /> Change Font text inside
                the bubbles
              </div>
              <div>
                <img src={tickIcon} alt="tick-mark" /> Change bubbles &amp; font
                color
              </div>
              <div>
                <img src={tickIcon} alt="tick-mark" /> Opacity effects and much
                more
              </div>
            </div>
          </div>

          <div className="description-right">
            <div
              className="bubble-image"
              style={{ backgroundImage: `url(${imagePath})` }}
            >
              <div className="pro"> PRO </div>
            </div>

            <div className="become-pro-button">
              {isLoading && <Spinner />}
              {!isLoading && (
                <button onClick={becomeProHandler}>BECOME PRO</button>
              )}
              {error && <p className="modal-payment-bubbles__error">{error}</p>}
            </div>
          </div>
        </div>
      </div>
    </ModalProfile>
  );
};

export default ModalPaymentProBubbles;
