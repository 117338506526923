import * as actionTypes from "./actionTypes";

export const initialize_user = (userData, token) => {
  return {
    type: actionTypes.INITIALIZE_USER,
    userData: userData,
    token: token,
  };
};

export const update_user_state = (userData, token) => {
  return {
    type: actionTypes.UPDATE_USER_STATE,
    userData: userData,
    token: token,
  };
};

export const set_username = (name) => {
  return {
    type: actionTypes.SET_USERNAME,
    name,
  };
};
export const set_email = (email) => {
  return {
    type: actionTypes.SET_EMAIL,
    email,
  };
};

export const set_ai_images_remaining = (ai_images_left) => {
  return {
    type: actionTypes.SET_AI_IMAGES_REMAINING,
    ai_images_left,
  };
};

export const logout_user = () => {
  return {
    type: actionTypes.LOGOUT_USER,
  };
};
