import React, { Fragment } from "react";
import Media from "react-media";
import "../assets/styles/pages/_homepage.scss";

import SidebarLeft from "../containers/SidebarLeft";
import MiddleBar from "../containers/MiddleBar";
import SidebarRight from "../containers/SidebarRight";
import GoogleAds from "../ads/GoogleAds";

const Homepage = (props) => {
  return (
    <>
      <GoogleAds />
      <div className="discount-banner">
        Use code 50OFF at checkout to get 50% discount on the annual or lifetime
        pro subscriptions
      </div>
      <div className="homepage">
        <Media queries={{ tablet: "(max-width: 1050px)" }}>
          {(matches) =>
            matches.tablet ? (
              <Fragment>
                <MiddleBar />
                <div className="homepage__mobile-container">
                  <SidebarLeft history={props.history} />
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <SidebarLeft history={props.history} />
                <MiddleBar />
                <SidebarRight />
              </Fragment>
            )
          }
        </Media>
      </div>
    </>
  );
};

export default Homepage;
