import { useCallback, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router";
import { useDispatch } from "react-redux";
import useHttp from "./hooks/use-http";
import { update_user_state } from "./store/actions/authActions";
import Homepage from "./pages/Homepage";
import MagicLoginUser from "./components/MagicLoginUser";
import ProfilePage from "./pages/ProfilePage";
import CollectionPage from "./pages/CollectionPage";
import HomepagePayment from "./pages/HomepagePayment";
import Spinner from "./UI/Spinner";

const App = () => {
  const { isLoading, sendRequest: sendGetUserRequest } = useHttp();
  let contentLoggedIn = null;
  const dispatch = useDispatch();
  const localStorageUserToken = JSON.parse(localStorage.getItem("userToken"));

  const handleGetUserResponse = useCallback(
    (response) => {
      dispatch(update_user_state(response.user, localStorageUserToken));
    },
    [dispatch, localStorageUserToken]
  );

  useEffect(() => {
    if (localStorageUserToken) {
      sendGetUserRequest(
        {
          url: "/api/get-user",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorageUserToken}`,
          },
        },
        handleGetUserResponse
      );
    }
  }, [localStorageUserToken, sendGetUserRequest, handleGetUserResponse]);

  if (localStorageUserToken) {
    contentLoggedIn = (
      <Switch>
        <Route path="/profile" component={ProfilePage} />
        <Route path="/collections" component={CollectionPage} />
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      {isLoading && (
        <div className="loading">
          <Spinner spinnerStyle="lds-ripple" />
        </div>
      )}
      <Route path="/" exact component={Homepage} />
      <Route path="/magic-login" component={MagicLoginUser} />
      <Route path="/payment-success" component={HomepagePayment} />

      {contentLoggedIn}
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default App;
