import React from 'react';

import '../assets/styles/UI/_spinner.scss';

const Spinner = props => {

    if (props.spinnerStyle === 'lds-ripple')
        return <div className="lds-ripple"><div></div><div></div></div>
    if (props.spinnerStyle === 'loader')
        return <div className="loader">Loading...</div>
}

export default Spinner;