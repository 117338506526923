import * as actionTypes from "./actionTypes";

export const set_background_image = (image, height,id) => {
  return {
    type: actionTypes.SET_BACKGROUND_IMAGE,
    activeImage: image,
    height,
    id
  }
}


