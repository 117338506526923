import * as actionTypes from "../actions/actionTypes";

const initialState = {
  allBubbles: {
    1: [],
  },
  activeContainerId: 1,
  activeBubbleId: null,
  currentContainersCount: 1,
  // nextUniqueIdIncrease:0
  // activeBubbleData: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INITIALIZE_BUBBLE_COMPONENT:
      let containerBubblesCopy = state.allBubbles[action.containerId].slice();
      // if (!state.allBubbles[action.containerId]) {

      //     containerBubblesCopy.push({
      //         bubbleId: 0,
      //         fontStyle: action.fontStyle,
      //         color: action.color,
      //         opacity: action.opacity,
      //         degree: 0
      //     })
      //     return {
      //         ...state,
      //         allBubbles: {
      //             ...state.allBubbles,
      //             [action.containerId]: containerBubblesCopy
      //         },
      //         activeContainerId: action.containerId,
      //         activeBubbleId: action.bubbleId,
      //         currentContainersCount:state.currentContainersCount+1
      //     }
      // }

      //let found = false;
      // let newActiveBubbleData = null;
      // for (let item in state.allBubbles) {
      // if (state.allBubbles[item].containerId === action.containerId) {

      // for (let innerItem in state.allBubbles[action.containerId]) {
      // let nextId = state.nextUniqueIdIncrease;
      // state.allBubbles[action.containerId].forEach(innerItem => {
      //     if (innerItem.bubbleId === action.bubbleId) {
      //         containerBubblesCopy.push({
      //             ...innerItem,
      //             bubbleId: nextId,
      //             fontStyle: action.fontStyle,
      //             color: action.color,
      //             opacity: action.opacity,
      //             degree: 0
      //         });
      //         nextId++;
      //         found = true;
      //         // newActiveBubbleData = {
      //         //     ...innerItem,
      //         //     bubbleId: action.bubbleId,
      //         //     fontStyle: action.fontStyle,
      //         //     color: action.color,
      //         //     opacity: action.opacity,
      //         //     degree: 0
      //         // };
      //     } else {
      //         containerBubblesCopy.push({
      //             ...innerItem,
      //         });
      //     }
      // });
      // allBubblesCopy.push({
      //     ...state.allBubbles[item],
      //     fontStyle: action.fontStyle,
      //     color: action.color,
      //     opacity: action.opacity,
      //     degree: 0

      // });
      // found = true;
      // } else {
      //     allBubblesCopy.push({
      //         ...state.allBubbles[item],
      //     });
      // }
      // }
      // if (!found) {
      //     allBubblesCopy.push({
      //         id: action.id,
      //         fontStyle: action.fontStyle,
      //         color: action.color,
      //         opacity: action.opacity
      //     })
      // }
      // if (!found) {
      containerBubblesCopy.push({
        activeImage: action.activeImage,
        variations: action.variations,
        bubbleId: action.bubbleId,
        fontStyle: action.fontStyle,
        color: action.color,
        opacity: action.opacity,
        opacityBubble: action.opacityBubble,
        degree: 0,
        degreeTmp: 0,
      });
      // newActiveBubbleData = {
      //     bubbleId: action.bubbleId,
      //     fontStyle: action.fontStyle,
      //     color: action.color,
      //     opacity: action.opacity,
      //     degree: 0
      // }
      // }
      return {
        ...state,
        allBubbles: {
          ...state.allBubbles,
          [action.containerId]: containerBubblesCopy,
        },
        activeContainerId: action.containerId,
        activeBubbleId: action.bubbleId,
        // nextUniqueIdIncrease:state.nextUniqueIdIncrease+1
        // activeBubbleData: { ...newActiveBubbleData }
      };
    case actionTypes.CLOSE_BUBBLE:
      let containerBubblesCopyRemove = [];
      // let newIdGenerate = 0;
      state.allBubbles[action.containerId].forEach((innerItem, index) => {
        if (innerItem.bubbleId === action.id) {
        } else {
          containerBubblesCopyRemove.push({
            ...innerItem,
            // bubbleId: newIdGenerate
          });
          // newIdGenerate++;
        }
      });
      return {
        ...state,
        allBubbles: {
          ...state.allBubbles,
          [action.containerId]: containerBubblesCopyRemove,
        },
        activeContainerId: action.containerId,
        activeBubbleId: null,
      };
    case actionTypes.REMOVE_RND_CONTAINER:
      let copyNewBubblesState = {};
      let itemGenerator = 1;

      Object.keys(state.allBubbles).map((key) => {
        if (+key !== action.id) {
          copyNewBubblesState = {
            ...copyNewBubblesState,
            [itemGenerator]: state.allBubbles[key].slice(),
          };
          itemGenerator++;
        }

        return null;
      });

      return {
        ...state,
        allBubbles: itemGenerator === 1 ? { 1: [] } : copyNewBubblesState,
        // activeContainerId: itemGenerator===1 ? 1 : itemGenerator-1,
        activeBubbleId: null,
        currentContainersCount: itemGenerator === 1 ? 1 : itemGenerator - 1,
      };
    case actionTypes.ADD_NEW_RND_CONTAINER:
      return {
        ...state,
        allBubbles: {
          ...state.allBubbles,
          [state.currentContainersCount + 1]: [],
        },
        currentContainersCount: state.currentContainersCount + 1,
      };

    case actionTypes.SET_ACTIVE_RND_CONTAINER:
      return {
        ...state,
        activeContainerId: action.id,
      };
    case actionTypes.SET_ACTIVE_FONT:
      let allBubblesCopy2 = [];
      for (let item in state.allBubbles[action.containerId]) {
        if (
          state.allBubbles[action.containerId][item].bubbleId ===
          action.bubbleId
        ) {
          allBubblesCopy2.push({
            ...state.allBubbles[action.containerId][item],
            fontStyle: action.fontStyle,
          });
        } else {
          allBubblesCopy2.push({
            ...state.allBubbles[action.containerId][item],
          });
        }
      }
      return {
        ...state,
        allBubbles: {
          ...state.allBubbles,
          [action.containerId]: allBubblesCopy2,
        },
      };
    case actionTypes.SET_ACTIVE_COLOR:
      let allBubblesCopy = [];
      for (let item in state.allBubbles[action.containerId]) {
        if (
          state.allBubbles[action.containerId][item].bubbleId ===
          action.bubbleId
        ) {
          allBubblesCopy.push({
            ...state.allBubbles[action.containerId][item],
            color: action.color,
          });
        } else {
          allBubblesCopy.push({
            ...state.allBubbles[action.containerId][item],
          });
        }
      }
      return {
        ...state,
        allBubbles: {
          ...state.allBubbles,
          [action.containerId]: allBubblesCopy,
        },
      };
    case actionTypes.SET_ACTIVE_OPACITY:
      let allBubblesCopyOpacity = [];
      for (let item in state.allBubbles[action.containerId]) {
        if (
          state.allBubbles[action.containerId][item].bubbleId ===
          action.bubbleId
        ) {
          allBubblesCopyOpacity.push({
            ...state.allBubbles[action.containerId][item],
            opacity: action.opacity,
          });
        } else {
          allBubblesCopyOpacity.push({
            ...state.allBubbles[action.containerId][item],
          });
        }
      }
      return {
        ...state,
        allBubbles: {
          ...state.allBubbles,
          [action.containerId]: allBubblesCopyOpacity,
        },
      };
    case actionTypes.SET_ACTIVE_OPACITY_BUBBLE:
      let allBubblesCopyOpacityBubble = [];
      for (let item in state.allBubbles[action.containerId]) {
        if (
          state.allBubbles[action.containerId][item].bubbleId ===
          action.bubbleId
        ) {
          allBubblesCopyOpacityBubble.push({
            ...state.allBubbles[action.containerId][item],
            opacityBubble: action.opacity,
          });
        } else {
          allBubblesCopyOpacityBubble.push({
            ...state.allBubbles[action.containerId][item],
          });
        }
      }
      return {
        ...state,
        allBubbles: {
          ...state.allBubbles,
          [action.containerId]: allBubblesCopyOpacityBubble,
        },
      };

    case actionTypes.SET_ROTATION_DEGREE:
      let allBubblesCopyDegree = [];
      for (let item in state.allBubbles[action.containerId]) {
        if (
          state.allBubbles[action.containerId][item].bubbleId ===
          action.bubbleId
        ) {
          let newDegree = 0;
          let newDegreeTemp = 0;
          let newImagePath = "";

          const degreeSum =
            state.allBubbles[action.containerId][item].degreeTmp +
            action.degree;
          if (degreeSum >= 360 || degreeSum < -300 || degreeSum === 0) {
            newDegree = 0;
            newDegreeTemp = 0;
            newImagePath =
              state.allBubbles[action.containerId][item].variations.right;
          } else {
            if (degreeSum === 60 || degreeSum === -300) {
              newDegree = 0;
              newDegreeTemp = degreeSum;
              newImagePath =
                state.allBubbles[action.containerId][item].variations.center;
            } else if (degreeSum === 120) {
              newDegree = 0;
              newDegreeTemp = degreeSum;
              newImagePath =
                state.allBubbles[action.containerId][item].variations.left;
            } else if (degreeSum === -240) {
              newDegree = 0;
              newDegreeTemp = degreeSum;
              newImagePath =
                state.allBubbles[action.containerId][item].variations.left;
            } else if (degreeSum === 180 || degreeSum === -180) {
              newDegree = 180;
              newDegreeTemp = degreeSum;
              newImagePath =
                state.allBubbles[action.containerId][item].variations.right;
            } else if (degreeSum === 240 || degreeSum === -120) {
              newDegree = 180;
              newDegreeTemp = degreeSum;
              newImagePath =
                state.allBubbles[action.containerId][item].variations.center;
            } else if (degreeSum === 300 || degreeSum === -60) {
              newDegree = 180;
              newDegreeTemp = degreeSum;
              newImagePath =
                state.allBubbles[action.containerId][item].variations.left;
            }
          }

          allBubblesCopyDegree.push({
            ...state.allBubbles[action.containerId][item],
            degree: newDegree,
            degreeTmp: newDegreeTemp,
            activeImage: newImagePath,
          });
        } else {
          allBubblesCopyDegree.push({
            ...state.allBubbles[action.containerId][item],
          });
        }
      }
      return {
        ...state,
        allBubbles: {
          ...state.allBubbles,
          [action.containerId]: allBubblesCopyDegree,
        },
      };
    case actionTypes.SET_ACTIVE_BUBBLE:
      return {
        ...state,
        activeContainerId: action.containerId,
        activeBubbleId: action.bubbleId,
        // newActiveBubbleData: { ...state.allBubbles[action.containerId][action.bubbleId] }
      };

    default:
      return state;
  }
};

export default reducer;
