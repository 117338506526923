import * as actionTypes from "../actions/actionTypes";

const initialState = {
  currentUser: {},
  userToken: null,
  isLoggedIn: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INITIALIZE_USER:
      localStorage.setItem("currentUser", JSON.stringify(action.userData));
      localStorage.setItem("userToken", JSON.stringify(action.token));

      return {
        currentUser: action.userData,
        userToken: action.token,
        isLoggedIn: !!action.token,
      };

    case actionTypes.UPDATE_USER_STATE:
      localStorage.setItem("currentUser", JSON.stringify(action.userData));
      localStorage.setItem("userToken", JSON.stringify(action.token));

      return {
        currentUser: action.userData,
        userToken: action.token,
        isLoggedIn: !!action.token,
      };
    case actionTypes.LOGOUT_USER:
      localStorage.removeItem("currentUser");
      localStorage.removeItem("userToken");

      return {
        currentUser: {},
        userToken: null,
        isLoggedIn: false,
      };
    case actionTypes.SET_USERNAME:
      let currentUserCopy = { ...state.currentUser };
      currentUserCopy.name = action.name;
      return {
        ...state,
        currentUser: currentUserCopy,
      };
    case actionTypes.SET_EMAIL:
      let currentUserCopyEmail = { ...state.currentUser };
      currentUserCopyEmail.email = action.email;
      return {
        ...state,
        currentUser: currentUserCopyEmail,
      };
    case actionTypes.SET_AI_IMAGES_REMAINING:
      let currentUserCopyAiImages = { ...state.currentUser };
      currentUserCopyAiImages.ai_images_left = action.ai_images_left;
      return {
        ...state,
        currentUser: currentUserCopyAiImages,
      };

    default:
      return state;
  }
};

export default reducer;
