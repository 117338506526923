import "../assets/styles/UI/_mobile-menu.scss";
import { ReactComponent as IconMenu } from "../assets/images/icons/iconMenu.svg";

const MobileMenu = (props) => {
  return (
    <div className="mobile-menu">
      <button className="mobile-menu__btn" onClick={props.onClick}>
        <IconMenu />
      </button>
    </div>
  );
};

export default MobileMenu;
