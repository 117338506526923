import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import "../assets/styles/UI/_backdrop.scss";
const BackdropComponent = (props) => {
  let content = (
    <div
      className={props.className ? props.className : "backdrop"}
      onClick={props.onClose}
    />
  );
  return props.show ? content : null;
};

const Backdrop = (props) => {
  const portalElement = document.getElementById(
    props.id ? props.id : "overlays"
  );
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <BackdropComponent
          show={props.show}
          onClose={props.onClose}
          className={props.className}
        />,
        portalElement
      )}
      {ReactDOM.createPortal(props.children, portalElement)}
    </Fragment>
  );
};

export default Backdrop;
