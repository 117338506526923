
import * as actionTypes from "./actionTypes";

export const add_new_rnd_container = () => {
    return {
        type: actionTypes.ADD_NEW_RND_CONTAINER
    }
}

export const remove_rnd_container = (id) => {
    return {
        type: actionTypes.REMOVE_RND_CONTAINER,
        id
    }
}
export const reset_rnd_state = () => {
    return {
        type: actionTypes.RESET_RND_STATE,
    }
}

export const set_active_rnd_container = (id) => {
    return {
        type: actionTypes.SET_ACTIVE_RND_CONTAINER,
        id
    }
}
export const set_background_image = (image, height, id) => {
    return {
        type: actionTypes.SET_BACKGROUND_IMAGE,
        activeImage: image,
        height,
        id
    }
}

export const set_bubble = (bubbleData) => {
    return {
        type: actionTypes.SET_BUBBLE,
        bubbleData
    }
}

export const close_bubble = (id, containerId = false) => {
    return {
        type: actionTypes.CLOSE_BUBBLE,
        id: id,
        containerId
    }
}

