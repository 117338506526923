import React from "react";
import Media from "react-media";

//styles
import "../assets/styles/components/_share-options-header.scss";

//icons
import { ReactComponent as IconSave } from "../assets/images/icons/iconSave.svg";
import { ReactComponent as IconDownload } from "../assets/images/icons/iconDownload.svg";
import { ReactComponent as IconShare } from "../assets/images/icons/iconShare.svg";

const ShareOptionsHeader = (props) => {
  return (
    <div className="share-options">
      <button
        className="share"
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          props.clicked("share");
        }}
        disabled={props.disabled}
      >
        <IconShare />
        <span>Share</span>
      </button>
      <button
        className="save"
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          props.clicked("save");
        }}
        disabled={props.disabled}
      >
        <IconSave />
        <Media queries={{ phone: "(max-width: 500px)" }}>
          {(matches) =>
            matches.phone ? <span>Save</span> : <span>Save to Collection</span>
          }
        </Media>
      </button>
      <button
        className="download"
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          props.clicked("download");
        }}
        disabled={props.disabled}
      >
        <IconDownload />
        <span>Download</span>
      </button>
    </div>
  );
};

export default ShareOptionsHeader;
