import { ReactComponent as IconCorrect } from "../assets/images/icons/iconCorrect.svg";
import { ReactComponent as IconClose } from "../assets/images/icons/cancel.svg";

import "../assets/styles/UI/_message.scss";

const Message = (props) => {
  return (
    <div className="message__container message__container--success">
      <div className="message__content">
        <IconCorrect />
        <p>{props.text}</p>
        <button onClick={props.onClose}>
          <IconClose />
        </button>
      </div>
    </div>
  );
};

export default Message;
