import React from "react";
import { ChromePicker } from "react-color";

import closeIcon from "../assets/images/icons/close.svg";

import "../assets/styles/UI/_color-picker.scss";

const ColorPicker = (props) => {
  return (
    <div className={props.styles ? props.styles : ""}>
      <img
        src={closeIcon}
        alt="close-icon"
        className="close-icon"
        onClick={() => props.closeColorPicker()}
      />
      <ChromePicker
        color={props.color}
        onChange={(color, event) => {
          event.stopPropagation();
          props.setColor(color.hex);
        }}
        width={"180px"}
      />
    </div>
  );
};

export default ColorPicker;
