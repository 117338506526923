import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useHttp from "../hooks/use-http";
import { initialize_bubbles } from "../store/actions/sideBarBubblesActions";

//styles
import "../assets/styles/components/_comments.scss";
import Bubbles from "./Bubbles";
import Spinner from "../UI/Spinner";

const Comments = (props) => {
  const dispatch = useDispatch();
  const { isLoading, error, sendRequest: sendGetBubblesRequest } = useHttp();
  const sideImages = useSelector(
    (state) => state.sideBarBubblesReducer.bubblesSidebar
  );

  const activeComponent = useSelector((state) => {
    let activeId = state.rndContainersReducer.activeRndContainerId;
    return state.rndContainersReducer.containers.find(
      (item) => item.id === activeId
    ).activeImage
      ? activeId
      : null;
  });

  let items = null;
  if (sideImages) {
    items = sideImages.map((item, index) => {
      return (
        <Bubbles
          item={item}
          key={item.variations.right}
          history={props.history}
        />
      );
    });
  }

  const handleGetBubblesResponse = useCallback(
    (response) => {
      dispatch(initialize_bubbles(response.data));
    },
    [dispatch]
  );

  useEffect(() => {
    sendGetBubblesRequest(
      {
        url: "/api/bubbles",
        headers: {
          Accept: "application/json",
        },
      },
      handleGetBubblesResponse
    );
  }, [sendGetBubblesRequest, handleGetBubblesResponse]);

  return (
    <div className="comments">
      {isLoading && <Spinner spinnerStyle="loader" />}
      {activeComponent ? null : <div className="hide-styles-properties"></div>}
      {error && <p>{error}</p>}
      {!isLoading && items}
    </div>
  );
};

export default Comments;
