import React, { useState } from "react";
import { useSelector } from "react-redux";

//styles
import "../assets/styles/components/_bubbles-tab.scss";

//components
import TextProperties from "./TextProperties";
import ModalPaymentProBubbles from "../UI/ModalPaymentProBubbles";
import ModalCreateLogin from "../UI/ModalCreateLogin";
import Auxilary from "../hoc/Auxilary";
import Backdrop from "../UI/Backdrop";
import Comments from "../components/Comments";

//icons
import { ReactComponent as IconEdit } from "../assets/images/icons/edit.svg";
import { ReactComponent as IconEditSelected } from "../assets/images/icons/editSelected.svg";
import { ReactComponent as IconComment } from "../assets/images/icons/comment.svg";
import { ReactComponent as IconCommentSelected } from "../assets/images/icons/commentSelected.svg";

const BubblesTab = (props) => {
  const userPlan = useSelector((state) => state.authReducer.currentUser.is_pro);
  const [activeTab, setActiveTab] = useState(0);
  const [modalPayment, setModalPayment] = useState(false);
  const [openModal, setOpenModal] = useState({
    open: false,
    type: null,
  });

  const openModalHandler = (type) => {
    setOpenModal({
      open: true,
      type,
    });
    setModalPayment(false);
  };
  const closeModalHandler = () => {
    setOpenModal({
      open: false,
      type: null,
    });
  };

  const handleCreateLoginResponse = () => {
    setOpenModal({
      open: false,
      type: null,
    });
  };

  const closeModalPaymentHandler = (event) => {
    event?.stopPropagation();
    setModalPayment(false);
  };

  const activeComponent = useSelector((state) => {
    if (
      state.stylesReducer.activeContainerId === null ||
      state.stylesReducer.activeBubbleId === null
    )
      return null;
    return state.stylesReducer.allBubbles[
      state.stylesReducer.activeContainerId
    ].find((item) => item.bubbleId === state.stylesReducer.activeBubbleId);
  });

  const activeComponentID = useSelector(
    (state) => state.stylesReducer.activeContainerId
  );

  const subscribeHandler = () => {
    if (!userPlan && !modalPayment && !openModal.open) {
      setModalPayment(true);
    }
  };

  let tabActiveComponent = <Comments history={props.history} />;
  if (activeTab === 1)
    tabActiveComponent = (
      <TextProperties
        activeComponent={activeComponent}
        activeComponentID={activeComponentID}
        onClick={subscribeHandler}
      />
    );

  return (
    <div>
      <div className="bubble-tabs">
        <button
          className={
            activeTab === 0 ? "bubble-tabs-btn active" : "bubble-tabs-btn"
          }
          onClick={() => setActiveTab(0)}
        >
          {activeTab === 0 ? <IconCommentSelected /> : <IconComment />}
        </button>
        <button
          className={
            activeTab === 1 ? "bubble-tabs-btn active" : "bubble-tabs-btn"
          }
          onClick={() => setActiveTab(1)}
        >
          {activeTab === 1 ? <IconEditSelected /> : <IconEdit />}
        </button>
      </div>

      {tabActiveComponent}

      {modalPayment && (
        <ModalPaymentProBubbles
          onClose={closeModalPaymentHandler}
          openCreateLogin={() => openModalHandler("create")}
          bubbleImagePath={null}
        />
      )}
      {openModal.open && (
        <Auxilary>
          <Backdrop show={true} onClose={closeModalHandler}>
            <ModalCreateLogin
              type={openModal.type}
              closeModal={closeModalHandler}
              handleCreateLoginResponse={handleCreateLoginResponse}
              subscribe
            />
          </Backdrop>
        </Auxilary>
      )}
    </div>
  );
};

export default BubblesTab;
