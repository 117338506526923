import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

const GoogleAds = () => {
  const adDiv = useRef();
  const isUserPro = useSelector(
    (state) => state.authReducer.currentUser.is_pro
  );

  useEffect(() => {
    if (!isUserPro && adDiv.current) {
      const script = document.createElement("script");

      script.src =
        "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7338790429328897";

      script.async = true;

      script.crossOrigin = "anonymous";

      adDiv.current.appendChild(script);
    }
  }, [isUserPro]);

  if (isUserPro) {
    return null;
  }

  return <div ref={adDiv}></div>;
};

export default GoogleAds;
