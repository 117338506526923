import * as actionTypes from "../actions/actionTypes";

const initialState = {
  bubblesSidebar: [],
  openedProBubblePath: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_OPENED_PRO_BUBBLE:
      return {
        ...state,
        openedProBubblePath: action.path,
      };

    case actionTypes.INITIALIZE_BUBBLES:
      return {
        ...state,
        bubblesSidebar: action.bubblesData,
      };
    default:
      return state;
  }
};

export default reducer;
