import { useState, useCallback } from "react";
import { domToPng, domToJpeg } from "modern-screenshot";

export const useScreenshot = (options) => {
  const { ref } = options || {};
  const [image, setImage] = useState();
  const [isLoading, setLoading] = useState(false);

  const takeScreenshot = useCallback(async (type) => {
    setLoading(true);
    let tempImage;

    try {
      const body = document.getElementById("root");

      if (type === "jpg") {
        tempImage = await domToJpeg(ref?.current || body, {
          quality: 1,
        });
      } else {
        tempImage = await domToPng(ref?.current || body, {
          quality: 1,
        });
      }

      setImage(tempImage);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);

      return tempImage;
    }
  }, []);

  const clear = useCallback(() => setImage(undefined), []);

  return { image, takeScreenshot, isLoading, clear };
};
